import React, { Fragment } from "react";
import Banner from "../Components/home/BannerSection";
import ServiceSection from "../Components/home/ServicesSection";
import JoinIds from "../Components/home/JoinIds";
import EffectiveProcess from "../Components/home/EffectiverProcess";
import Client from "../Components/home/Client";
import BlogSection from "../Components/home/BlogSection";
import CaseStudySection from "../Components/home/CaseStudySection";
import CeoSection from "../Components/home/CeoSection";
import Testimonial from "../Components/home/Testimonial";
import ContactUsAbout from "../Components/card/ContactUsAbout";
import withHelmet from "../Components/hoc/withHelmet";

const Home = () => {
  return (
    <Fragment>
      <Banner />
      <ServiceSection />
      <JoinIds />
      <Client />
      <EffectiveProcess />
      <CeoSection />
      <CaseStudySection />

      <BlogSection />

      <Testimonial />
      <ContactUsAbout />
      {/* <Opportunity /> */}
    </Fragment>
  );
};

const helmetConfig = {
  htmlLang: "en",
  title: "Publishing and Content Management Services",
  metaDescription: "First Page",
  metaThemeColor: "#E6E6FA",
  metaTags: [{ name: "author", content: "Your Name" }],
};

export default withHelmet(Home, helmetConfig);
