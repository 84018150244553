import Cost from "../assets/images/Svg/Cost.svg"
import Customized from "../assets/images/Svg/Customized.svg"
import Domain from "../assets/images/Svg/Domain.svg"
import Methodologies from "../assets/images/Svg/Methodologies.svg"
import Quality from "../assets/images/Svg/Quality.svg"
import Robust from "../assets/images/Svg/Robust.svg"
const JoinIdsCardData =[
    {
        Svg:Cost,
        Heading:"Reduce Cost",
        Subheading:"Guaranteed 10X ROI. Streamlined processes to reduce costs by up to 35%-40% ",
    },
    {
        Svg:Customized,
        Heading:"Customized Solutions",
        Subheading:"Our in-house developed tech-based solutions utilizing AI and RPA help make the process chain scalable by eliminating all friction points ",
    },
    {
        Svg:Domain,
        Heading:"Domain Expertise",
        Subheading:"200+ dedicated SMEs working 24*7 to ensure seamless processing and customer satisfaction",
    },
    {
        Svg:Quality,
        Heading:"Consistent Quality",
        Subheading:"Multi-level checks to maintain quality consistency of the output",
    },
    {
        Svg:Methodologies,
        Heading:"Reliable Methodologies",
        Subheading:"FIFO and LEAN methodology to ensure SLA adherence and help clients reduce time-to-market ",
    },
    {
        Svg:Robust,
        Heading:"Robust Infrastructure",
        Subheading:"Robust infrastructure along with data privacy assurance across all our locations based out of the UK, US, Europe, and India",
    },

]
export default  JoinIdsCardData