import React from "react";
// import FooterNewsLetter from "../footer/FooterNewsLetter";
import FooterAddress from "../footer/FooterAddresses";
const Footer = () => {
  return (
    <>
      {/* <FooterNewsLetter /> */}
      <FooterAddress />
    </>
  );
};

export default Footer;
