import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import Div from "../Layouts/Div";

import MobileCaseStudySection from "../../Components/home/MobileCaseStudySection";
import GoToTop from "../GoToTop";
import { useNavigate } from "react-router";
import MultiCarousel from "./Carousel";

const CaseStudySection = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <Div>
        <Stack mx={"auto"} maxWidth={"xl"} id="case_study_id">
          <Stack
            container
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
          >
            <Stack Item maxWidth={{ xs: "100%", md: "96%" }}>
              <Typography>
                <Typography
                  sx={{
                    typography: { xs: "displaySmSemi", md: "displayMdSemi" },
                  }}
                >
                  Discover Some of Our Case Studies
                </Typography>
                <Typography
                  sx={{ typography: { xs: "textLgReg", md: "textXlReg" } }}
                  my={2}
                >
                  Explore case studies of some of our successful customers
                  experiencing growth.
                </Typography>
              </Typography>
            </Stack>
            <Stack Item>
              <Button
                onClick={() => {
                  navigate("/case-study");
                }}
                size="medium"
                sx={[
                  {
                    width: "100%",
                    color: "white",
                    bgcolor: "primary.blue600",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.blue700",
                    },
                  },
                ]}
              >
                View All
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Div>

      <Stack ml={{ xs: 4, md: 10 }}>
        {/* <Divider  sx={{my:"3rem"}} variant="middle" /> */}
        <Stack sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <MultiCarousel />
        </Stack>
        <Stack sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
          <MobileCaseStudySection />
        </Stack>
      </Stack>
      <GoToTop />
    </>
  );
};
export default CaseStudySection;
