import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { useState } from "react";
import Layout from "./Components/Layouts/Layout";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import { darkTheme, lightTheme } from "./utils/Theme";
import BlogPage from "./Components/Layouts/BlogPage";
import BlogLanding from "./Components/Layouts/BlogLanding";
import CaseStudyInternalPageCard from "./Components/card/CaseStudyInternalPageCard";
import CaseStudyLanding from "./Components/casestudy/CaseStudyLanding";
import About from "./pages/About";
import NotFound from "./pages/NotFound";

function App() {
  const [dark, setDark] = useState(false);

  return (
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <CssBaseline />
      <Layout>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route index path="/about-us" element={<About />} />
          <Route path="/blogs">
            <Route
              index
              element={<BlogLanding dark={dark} setDark={setDark} />}
            />
            <Route
              path=":id"
              element={<BlogPage dark={dark} setDark={setDark} />}
            />
          </Route>
          <Route path="/case-study">
            <Route index element={<CaseStudyLanding />} />
            <Route path=":type" element={<CaseStudyInternalPageCard />} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </ThemeProvider>
  );
}

export default App;
