import { Controls, Player } from "@lottiefiles/react-lottie-player";
import React from "react";
import error from "../store/404/404-error.json";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div>
      <Stack
        minH={"70vh"}
        display="flex"
        justifyContent={"center"}
        alignItems="center"
      >
        <Stack
          pt={"5%"}
          pb={5}
          maxWidth={"md"}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Box maxWidth={{ xs: "70%", md: "50%" }}>
            <Player
              autoplay
              loop
              src={error}
              style={{ height: "300px", width: "300px" }}
            >
              <Controls
                visible={false}
                buttons={["play", "repeat", "frame", "debug"]}
              />
            </Player>
          </Box>

          <Typography
            my={3}
            mx={3}
            textAlign={"center"}
            sx={{ typography: "textLgReg", color: "primary.gray600" }}
          >
            We apologize for the inconvenience, the page you are trying to reach
            cannot be found. Please check the URL or head back to our home page
          </Typography>
          <Link to="/">
            <Button
              variant="contain"
              sx={[
                {
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 20px",
                  lineHeight: "24px",
                },
                {
                  "&:hover": {
                    bgcolor: "primary.blue700",
                  },
                },
              ]}
            >
              Back to home
            </Button>
          </Link>
        </Stack>
      </Stack>
    </div>
  );
};

export default NotFound;
