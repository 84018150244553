import React, { useEffect, useState } from "react";
import BlogContent from "../BlogContent";
import BlogHeader from "../BlogHeader";
// import Navbar from "./Navbar";
import GoToTop from "../GoToTop";
import blogs from "../../store/BlogsData";
import { useParams } from "react-router";
import { Stack } from "@mui/material";
import BlogSection from "../home/BlogSection";
import withHelmet from "../hoc/withHelmet";

const BlogPage = () => {
  const [blogData, setBlogData] = useState({});
  const params = useParams();

  useEffect(() => {
    setBlogData(blogs.filter((blog) => blog.id === parseInt(params.id)));
  }, [params]);

  if (blogData.length > 0) {
    return (
      <>
        <Stack
          width={"100%"}
          direction={"column"}
          justifyContent="center"
          display={"flex"}
        >
          <BlogHeader
            heading={blogData[0].name}
            intro={blogData[0].intro}
            addedBy={blogData[0].added_by}
            date={blogData[0].date}
          />
          <BlogContent
            image={blogData[0].image}
            content={blogData[0].content}
          />
          <BlogSection />
          <GoToTop />
        </Stack>
      </>
    );
  }

  return <>Blog Not Found</>;
};

const helmetConfig = {
  htmlLang: "en",
  title: "Publishing and Content Management Services",
  metaDescription: "First Page",
  metaThemeColor: "#E6E6FA",
  metaTags: [{ name: "author", content: "Your Name" }],
};
export default withHelmet(BlogPage, helmetConfig);
