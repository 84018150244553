import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Stack } from "@mui/system";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { Typography } from "@mui/material";
const ListedCard = (props) => {
  return (
    <>
      <List>
        <ListItem>
          <Stack display={"flex"} direction={"row"} spacing={"0.6rem"}>
            <Stack Item my={"0.6rem"}>
              <AiOutlineCheckCircle />
            </Stack>
            <Stack Item>
              <ListItemText sx={{ typography: { md: "textLgReg" } }}>
                {/* {props.listText} */}
                <Typography
                  dangerouslySetInnerHTML={{ __html: props.listText }}
                  sx={{ typography: { md: "textXlReg" }, margin: "0px" }}
                ></Typography>
              </ListItemText>
            </Stack>
          </Stack>
        </ListItem>
      </List>
    </>
  );
};

export default ListedCard;
