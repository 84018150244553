import React from "react";
import { Button, Typography, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ClientCards from "../card/ClientCards";
import ClientsCardData from "../../store/ClientCardData";
import Div from "../Layouts/Div";

const Client = () => {
  return (
    <Div bgColor={"primary.gray100"} my={8}>
      <Stack
        py={{ xs: 1, sm: 1, md: 2 }}
        // sx={{ my: "6rem" }}
        mx={"auto"}
        maxWidth={"xl"}
      >
        <Stack
          container
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          py={4}
        >
          <Stack Item sx={{ maxWidth: { xs: "100%", md: "60%" } }}>
            <Typography>
              <Typography
                sx={{
                  typography: { xs: "displaySmSemi", md: "displayMdSemi" },
                }}
              >
                Trusted By Global Clients Since 1989
              </Typography>
              <Typography
                sx={{ typography: { xs: "textLgReg", md: "textXlReg" } }}
                my={{ xs: "20px", md: "16px" }}
              >
                We empower clients to take the stress out of their publishing
                and content management processes so they can focus on what they
                are best at!
              </Typography>
            </Typography>
          </Stack>
          <Stack my={{ xs: "my-2" }}>
            <a
              href="https://idsil.com/contact-us/"
              style={{ textDecoration: "none" }}
            >
              <Button
                size="medium"
                sx={[
                  {
                    color: "white",
                    bgcolor: "primary.blue600",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                    width: "100%",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.blue700",
                    },
                  },
                ]}
              >
                Get in touch
              </Button>
            </a>
          </Stack>
        </Stack>
        <Grid container spacing={{ md: "2rem", xl: "4rem" }}>
          {ClientsCardData.map((ClientValue, Index) => (
            <Grid Item xs={12} sm={6} md={3}>
              <ClientCards
                Heading={ClientValue.Heading}
                Mainheading={ClientValue.Mainheading}
                Subheading={ClientValue.Subheading}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Div>
  );
};

export default Client;
