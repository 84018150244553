import React from "react";
import CaseStudyCard from "../card/CaseStudyCard";
import CaseStudyCardData from "../../store/CaseStudyCardData";
import Div from "../Layouts/Div";
import { Link } from "react-router-dom";
import { Stack, Grid, Typography, useMediaQuery } from "@mui/material";
import GoToTop from "../GoToTop";
import ContactUsAbout from "../card/ContactUsAbout";
import { useTheme } from "@emotion/react";

const CaseStudyLanding = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Stack>
        {/* <GetStarted /> */}
        <Div bgColor={"primary.gray100"}>
          <Stack
            sx={{ backgroundColor: "#f6f6f6" }}
            mx={"auto"}
            maxWidth={"xl"}
            my={8}
          >
            <Stack py={"5%"} sx={{ backgroundColor: "#f6f6f6" }}>
              <Typography
                sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
                // variant="textMdSemi"
                color="primary.blue600"
              >
                Case Studies
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "displayMdSemi", md: "displayLgSemi" },
                }}
                // variant="displayLgSemi"
              >
                Stories Behind Success
              </Typography>
              <Typography
                sx={{ typography: { xs: "textLgReg", md: "textXlReg" } }}
                // variant="textXlReg"
              >
                Explore how IDS brings client vision to life.
              </Typography>
            </Stack>
            <Stack
              mb={"5%"}
              spacing={3}
              direction={{ xs: "column", md: "row" }}
            ></Stack>
          </Stack>
        </Div>
      </Stack>
      <Div>
        <Stack my={10} mx={"auto"} maxWidth={"xl"}>
          <Grid container spacing={{ xs: 2, sm: 4, md: 6 }} width={"100%"}>
            {CaseStudyCardData.map((CaseStudyValue, index) => (
              <Grid item xs={12} sm={6} md={4}>
                <Stack
                  Item
                  sx={[
                    { position: "relative" },
                    { width: "100%" },
                    { height: "100%" },
                    { borderRadius: "20px" },
                  ]}
                  flexShrink={0}
                  className={
                    isMatch ? "caseStudy_Card_Mobile" : "caseStudy_Card"
                  }
                >
                  <Link
                    to={`/case-study/${CaseStudyValue.type}`}
                    style={{ textDecoration: "none" }}
                  >
                    <CaseStudyCard
                      className="caseStudy_Card_Content_Mobile"
                      Heading={CaseStudyValue.Heading}
                      SvgIcons={CaseStudyValue.SvgIcons}
                      SubHeading={CaseStudyValue.SubHeading}
                      Para={CaseStudyValue.Paragraph}
                    />
                  </Link>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Div>
      <ContactUsAbout />
      <GoToTop />
    </>
  );
};

export default CaseStudyLanding;
