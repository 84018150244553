import React from "react";
import { Typography, Stack, useMediaQuery } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import "../../assets/images/style.css";
import { useTheme } from "@emotion/react";

const CaseStudyCard = (props) => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Stack
        height="440px"
        direction="column"
        spacing={2}
        position="relative"
        sx={{
          px: { xs: "2rem", sm: "2rem", md: "1rem", xl: "2rem" },
          py: { xs: 2, sm: 2, md: 2 },
        }}
        className={
          isMatch ? "caseStudy_Card_Content_Mobile" : "caseStudy_Content"
        }
      >
        <Stack Item>
          <Typography
            sx={{
              typography: {
                xs: "textLgSemi",
                sm: "textLgSemi",
                md: "textLgReg",
              },
              bgcolor: "transparent",
              color: "primary.gray400",
            }}
          >
            {props.Heading}
          </Typography>
          <Typography
            sx={{
              typography: {
                xs: "displaySmSemi",
                sm: "displaySmSemi",
                md: "displayMdSemi",
              },
            }}
          >
            {props.SubHeading}
          </Typography>
        </Stack>
        <Stack Item>
          <Typography
            sx={{ typography: "textLgReg" }}
            className={
              isMatch
                ? "caseStudy_Card_Content_Mobile text-clamp-mobile"
                : "caseStudy_CardPara"
            }
          >
            {props.Para}
          </Typography>
        </Stack>
        <Stack
          sx={{ position: "absolute", bottom: "2rem" }}
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Stack Item className="read_more">
            <Typography
              sx={{
                typography: { xs: "textMdSemi", md: "textLgReg" },
              }}
            >
              Read More
            </Typography>
          </Stack>
          <Stack Item className="read_more_icons">
            <ArrowOutwardIcon />
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default CaseStudyCard;
