import React from "react";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";


const TestimonialCard = (props) => {
  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        color="primary.gray50"
        py={{xs:"1.2rem",md:"3rem"}}
        px={{xs:"1.2rem",md:"2rem"}}
      >
        <Typography sx={{typography:{md:"textSmSemi"}}}>{props.SubHeading}</Typography>
        <Typography  sx={{typography:{md:"displayMdMed"},width:{sm:"100%",md:"80%"}}}>{props.Heading}</Typography>
        <Typography my="1.3rem">
        <Avatar alt="Remy Sharp" src={props.Avtar}/>
        </Typography>
        <Typography sx={{typography:{md:"textMdSemi"}}}>{props.Name}</Typography>
        <Typography sx={{typography:{md:"textSmReg"}}}>{props.Designation}</Typography>
      </Stack>
    </>
  );
};

export default TestimonialCard;
