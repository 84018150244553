const CLientsCardData = [
    {
      Heading: "34+",
      Mainheading: "Years  experience",
      Subheading: "Bringing ideas to reality with our three decades of  experience",
    },
    {
      Heading: "70%",
      Mainheading: "Long-standing clients",
      Subheading: "Customers with 10+ years of loyalty",
    },
    {
      Heading: "800+",
      Mainheading: "Projects per year",
      Subheading: "From eBooks, abstracts, and media conversions to books & journals ",
    },
    {
      Heading: "200+",
      Mainheading: "Team members",
      Subheading: "Deep subject matter expertise and technical know-how ",
    },
  ];
  export default CLientsCardData;