import React from "react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography } from "@mui/material";
import EffectiveStepsBanner from "../../assets/images/effectivesteps_banner.webp";
import EffectiveMobileBanner from "../../assets/images/effectivesteps_mobilebanner.webp";
import Div from "../Layouts/Div";

const EffectiveProcess = () => {
  return (
    <Div>
      <Stack sx={{ my: { xs: 2, sm: 2, md: 20 } }} mx={"auto"} maxWidth={"xl"}>
        <Grid
          container
          spacing={{ md: "1rem", xl: "4rem" }}
          width={"100%"}
          justifyContent={"center"}
        >
          <Grid Item lg={3}>
            <Typography>
              <svg
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="4"
                  y="4.60596"
                  width="48"
                  height="48"
                  rx="24"
                  fill="#E0F2FE"
                />
                <path
                  d="M25 25.606V22.606C25 20.9491 23.6569 19.606 22 19.606C20.3431 19.606 19 20.9491 19 22.606C19 24.2628 20.3431 25.606 22 25.606H25ZM25 25.606V31.606M25 25.606H31M25 31.606V34.606C25 36.2628 23.6569 37.606 22 37.606C20.3431 37.606 19 36.2628 19 34.606C19 32.9491 20.3431 31.606 22 31.606H25ZM25 31.606H31M31 31.606H34C35.6569 31.606 37 32.9491 37 34.606C37 36.2628 35.6569 37.606 34 37.606C32.3431 37.606 31 36.2628 31 34.606V31.606ZM31 31.606V25.606M31 25.606V22.606C31 20.9491 32.3431 19.606 34 19.606C35.6569 19.606 37 20.9491 37 22.606C37 24.2628 35.6569 25.606 34 25.606H31Z"
                  stroke="#0086C9"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="4"
                  y="4.60596"
                  width="48"
                  height="48"
                  rx="24"
                  stroke="#F0F9FF"
                  stroke-width="8"
                />
              </svg>
            </Typography>
            <Typography
              sx={{ typography: { xs: "displaySmSemi", md: "displayMdSemi" } }}
            >
              Our Simple, Effective Four-Step Process
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textLgReg", md: "textXlReg" },
                my: "20px",
              }}
            >
              Our shared values keep us connected and guide us toward success.
            </Typography>
          </Grid>
          <Grid container lg={9} spacing={{ md: "1rem", xl: "4rem" }}>
            <Stack sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <img
                src={EffectiveStepsBanner}
                alt="effect_steps"
                width="100%"
                height="100%"
              />
            </Stack>
            <Stack
              sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              my={8}
            >
              <img
                src={EffectiveMobileBanner}
                alt="effect_steps"
                width="100%"
                height="100%"
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Div>
  );
};

export default EffectiveProcess;
