import React from 'react'
import { Stack } from '@mui/system'
// import { Typography } from '@mui/material'
import TestimonialCard from '../card/TestimonialCard'
import TestimonialCardData from '../../store/TestimonialCardData'
import Carousel from 'react-material-ui-carousel'
import Div from "../Layouts/Div"
const Testimonial = () => {
  return (
    <Div>
    <Stack sx={{my:{xs:2,sm:2,md:10} }} mx={"auto"} maxWidth={"xl"} height={"100%"}>
     <Stack py={{xs:"1.3rem",md:"1rem"}} sx={{bgcolor:"primary.gray700",  borderRadius:"16px"}}>
      <Carousel animation ="slide" duration="500" autoPlay={true}>
      {
        TestimonialCardData.map((TestimonialCardDataValue)=>(
            <TestimonialCard
            Heading = {TestimonialCardDataValue.Heading}
            SubHeading = {TestimonialCardDataValue.SubHeading}
            Avtar = {TestimonialCardDataValue.Avtar}
            Name ={TestimonialCardDataValue.Name}
            Designation ={TestimonialCardDataValue.Designation}/>
        ))
      }
      </Carousel>
      </Stack>
     </Stack> 
     </Div>
     )
}

export default Testimonial