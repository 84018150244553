import React from "react";
import { Grid, Stack, Typography, Button } from "@mui/material";
import Div from "../Layouts/Div";
import "../../assets/images/style.css";

const ContactUsAbout = () => {
  return (
    <Div>
      <Stack
        sx={{ my: "5rem" }}
        mx={"auto"}
        maxWidth={"xl"}
        className={"bgImage"}
      >
        <Grid
          container
          justifyContent="flex-center"
          py={{ md: "40px" }}
          px={{ xs: "1.5rem", sm: "3.5rem", md: "5rem" }}
          width={"100%"}
        >
          <Grid item lg={7} my={{ xs: "2rem", md: "20px" }}>
            <Typography
              sx={{ typography: { xs: "displayMdSemi", md: "displayLgSemi" } }}
            >
              Want to know more?
            </Typography>
            <Stack py={"16px"}>
              <Typography
                sx={{
                  typography: { xs: "textLgReg", md: "textXlReg" },
                  py: "10px",
                }}
                maxWidth={"sm"}
              >
                Curious about what we can do for your business? Don't hesitate
                to reach out – we're here to help.
              </Typography>
            </Stack>
            <Button
              size="medium"
              href="https://idsil.com/contact-us/"
              sx={[
                {
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 20px",
                  lineHeight: "24px",
                  marginTop: "48px",
                },
                {
                  "&:hover": {
                    bgcolor: "primary.blue700",
                  },
                },
              ]}
            >
              Get in touch
            </Button>
          </Grid>
          {/* <Grid Item lg={5}>
            <Stack width={{ xs: "80%", md: "100%" }}>
              <img src={Contents} alt="contents" width="100%" height="100%" />
            </Stack>
          </Grid> */}
        </Grid>
      </Stack>
    </Div>
  );
};

export default ContactUsAbout;
