import React from "react";
import { Typography } from "@mui/material";

const FooterTypography = (props) => {
  return (
    <>
      <Typography
        sx={{ typography: { xs: "textXlSemi", md: "textXlbold" } }}>
       {props.children}
      </Typography>
    </>
  );
};

export default FooterTypography;
