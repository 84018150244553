import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const ClientCards = (props) => {
  return (
    <div>
      <Card elevation={false} sx={{ backgroundColor: "primary.gray100" }}>
        <CardContent>
          <Typography
            sx={{
              typography: { xs: "displayLgSemi", md: "displayXlSemi" },
              color: "primary.blue700",
            }}
          >
            {props.Heading}
          </Typography>
          <Typography
            py={2}
            sx={{ typography: { xs: "textSmSemi", md: "textXlSemi" } }}
          >
            {props.Mainheading}
          </Typography>
          <Typography sx={{ typography: { xs: "textLgReg", md: "textLgReg" } }}>
            {props.Subheading}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientCards;
