import {
  FormControl,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useState } from "react";
import BlogCard from "../Components/BlogCard";
import Div from "../Components/Layouts/Div";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import blogs from "../store/BlogsData";
import { Link } from "react-router-dom";

export default function BlogSection() {
  const [sort, setSort] = useState("Newest");
  const [search, setSearch] = useState("");
  const handleChange = (event) => {
    setSort(event.target.value);
    blogs.reverse();
  };

  return (
    <Div bgColor="primary.white">
      <Stack
        display={"flex"}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: "16px" }}
        pt={"5%"}
        mb={"6rem"}
        maxWidth={"xl"}
        mx="auto"
        justifyContent={"space-between"}
      >
        <Paper
          component={"form"}
          sx={[
            {
              "&.MuiPaper-root": {
                backgroundColor: "primary.main",
                border: "1px solid",
                borderColor: "primary.gray77",
              },
              "&.MuiPaper-root:focus": {
                borderColor: "primary.blue600 ",
              },
              "&:hover": {
                borderColor: "primary.black",
              },
            },
          ]}
          elevation={0}
        >
          <IconButton>
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={[{ ml: 1, height: "48px" }]}
            placeholder="Search Blogs..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            inputProps={{ "aria-label": "search blogs" }}
          ></InputBase>
        </Paper>
        <FormControl
          component={"form"}
          sx={[
            {
              minWidth: "200px",
              "&.MuiPFormControl-root": {
                backgroundColor: "primary.main",
              },
            },
          ]}
          elevation={0}
        >
          <Select
            defaultValue="Newest"
            value={sort}
            sx={[
              {
                height: "48px",
              },
              {
                "&.Mui-focused": {
                  border: "1px solid ",
                  borderColor: "primary.blue600",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              },
            ]}
            onChange={handleChange}
          >
            <MenuItem value={"Newest"}>
              <ArrowDownwardIcon
                sx={{ width: "15px", verticalAlign: "middle", mr: "5px" }}
                color="primary.gray500"
              />
              Newest
            </MenuItem>

            <MenuItem value={"Oldest"}>
              <ArrowUpwardIcon
                sx={{ width: "15px", verticalAlign: "middle", mr: "5px" }}
                color="primary.gray500"
              />
              Oldest
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Stack
        display={"flex"}
        mb={"6rem"}
        justifyContent="center"
        alignItems={"center"}
      >
        <Stack maxWidth={"xl"}>
          <Grid
            container
            display={"flex"}
            // justifyContent="center"
            alignItems={"center"}
            spacing={5}
          >
            {blogs
              .filter(
                (items) =>
                  items.name.toLowerCase().includes(search.toLowerCase()) ||
                  items.half_content
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .map((blog) => (
                <Grid item xs={12} md={6} key={blog.id}>
                  <Link
                    to={`/blogs/${blog.id}`}
                    key={blog.id}
                    style={{ textDecoration: "none" }}
                  >
                    <BlogCard
                      name={blog.name}
                      thumbnail={blog.thumbnail}
                      content={blog.half_content}
                    />
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Stack>
      </Stack>
      {/* <Divider sx={{ mt: "3%" }} variant="middle" /> */}
      {/* <Stack py={4} pt={5} display={"flex"} alignItems="center">
        <Pagination
          count={10}
          siblingCount={0}
          variant="text"
          shape="rounded"
        />
      </Stack> */}
      {/* <Divider sx={{ pb: "5%" }} variant="middle" /> */}
    </Div>
  );
}
