import React from "react";
import { Card, CardContent, Typography, Stack } from "@mui/material";

const JoinIdsCard = (props) => {
  return (
    <Stack mt={"2rem"}>
      <Card elevation={false}>
        <CardContent>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack Item>
              <img src={props.Svg} alt="ids" />
            </Stack>
            <Typography
              sx={{ typography: { xs: "textXlSemi", md: "textXlSemi" } }}
              my={2}
            >
              {props.Heading}
            </Typography>
            <Typography
              sx={{ typography: { md: "textMdReg" }, textAlign: "center" }}
            >
              {props.Subheading}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default JoinIdsCard;
