import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Div from "./Layouts/Div";

export default function BlogHeader(props) {
  return (
    <Div bgColor="primary.blue800">
      <Stack textAlign={"center"} display={"flex"} py={4} alignItems={"center"}>
        <Typography
          pt={8}
          textAlign={"center"}
          color="primary.blue200"
          sx={{
            typography: { xs: "textSmSemi", md: "textMdSemi" },
          }}
        >
          {props.date}
        </Typography>
        <Typography
          py={3}
          textAlign={"center"}
          color="primary.white"
          sx={{ typography: { xs: "displayMdSemi", md: "displayLgSemi" } }}
        >
          {props.heading}
        </Typography>
        <Typography
          py={2}
          pb={25}
          maxWidth={"md"}
          textAlign={"center"}
          color="primary.blue200"
          sx={{ typography: { xs: "textLgReg", md: "textXlReg" } }}
        >
          {props.intro}
        </Typography>
      </Stack>
    </Div>
  );
}
