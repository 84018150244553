import React from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Typography, Button, Stack } from "@mui/material";
import CmpBanner from "../../assets/images/banner.webp";
import Div from "../Layouts/Div";

const Banner = () => {
  return (
    <Div>
      <Stack
        maxWidth={"xl"}
        mx={"auto"}
        display="flex"
        alignContent={"center"}
        sx={{ minHeight: "98vh", placeContent: "center" }}
      >
        <Grid container my={{ xs: "6rem", sm: "6rem" }} mt={{ md: "10rem" }}>
          <Grid item lg={7}>
            <Stack sx={{ px: { md: "0rem", xl: "0rem" } }} width="100%">
              <Stack
                sx={{
                  maxWidth: { md: "520px", xl: "800px" },
                  textAlign: { xs: "center", sm: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    typography: { xs: "displayXlBold", md: "displayXlBold" },
                  }}
                >
                  End-to-End Publishing and Content Management Services{" "}
                </Typography>
                <Typography
                  sx={{
                    typography: { xs: "textLgreg", md: "textXlReg" },
                    color: "primary.gray500",
                  }}
                  my={"16px"}
                >
                  Simplifying publishing and content management by leveraging
                  technology and human expertise.
                </Typography>
                <Typography
                  sx={{
                    typography: { md: "displayXsSemi" },
                    color: "primary.blue600",
                  }}
                  mt={2}
                >
                  Any Media. Any Platform. Any Format
                </Typography>
                <Stack width={{ md: "30%" }}>
                  <a
                    href="https://idsil.com/contact-us/"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      size="medium"
                      sx={[
                        {
                          typography: { md: "textLgSemi" },
                          color: "white",
                          bgcolor: "primary.blue600",
                          fontSize: "16px",
                          fontWeight: "600",
                          padding: "12px 20px",
                          lineHeight: "24px",
                          marginTop: "40px",
                        },
                        {
                          "&:hover": {
                            bgcolor: "primary.blue700",
                          },
                        },
                      ]}
                    >
                      Get in touch
                    </Button>
                  </a>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item lg={5}>
            <Stack
              sx={{ maxWidth: "450px", height: "100%" }}
              mx={{ xs: "2rem", sm: "3rem" }}
              my={"2rem"}
            >
              <img src={CmpBanner} alt="banner" width="100%" heigh="100%" />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Div>
  );
};

export default Banner;
