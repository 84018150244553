import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import "../../assets/images/style.css";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

function AboutCard(props) {
  return (
    <Stack height={"100%"}>
      <a
        href={props.link}
        target="_blank"
        style={{ height: "100%", color: "black", textDecoration: "none" }}
        rel="noreferrer"
      >
        <Stack
          p={4}
          className="caseStudy_Card"
          bgcolor={"primary.gray100"}
          height={"100%"}
          position="relative"
          sx={[
            {
              borderRadius: 2,
              cursor: "pointer",
              "&:hover": {
                boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
                cursor: "pointer",
                color: "white",
              },
            },
          ]}
        >
          <Stack>
            <Stack>
              <Stack>
                <Stack mt={-2}>
                  <Typography
                    sx={{ typography: { xs: "textLgSemi", md: "textXlSemi" } }}
                  >
                    {props.title}
                  </Typography>
                </Stack>
                <Stack mt={2} mb={4}>
                  <Typography
                    sx={{ typography: { xs: "textXsReg", md: "textMdReg" } }}
                  >
                    {props.description}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography
                    sx={{
                      mb: 2,
                      mt: 4,
                      typography: { xs: "textSmReg", md: "textMdReg" },
                      position: "absolute",
                      bottom: "0rem",
                    }}
                  >
                    Explore <ArrowOutward fontSize="8px" sx={{ mx: "4px" }} />
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </a>
    </Stack>
  );
}

export default AboutCard;
