const AboutCardData = [
  {
    Heading: "34+",
    Mainheading: "Years Industry Experience",
    Subheading:
      "Transforming digital space with in-depth knowledge & expertise",
  },
  {
    Heading: "1400+",
    Mainheading: "SMEs",
    Subheading: "Accelerating customer success with domain-specific expertise",
  },
  {
    Heading: "4",
    Mainheading: "Global presence",
    Subheading: "Creating impact worldwide, with global footprints",
  },
  {
    Heading: "2",
    Mainheading: "Joint Ventures",
    Subheading: "Providing cutting-edge solutions with industry-experts",
  },
];
export default AboutCardData;
