import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { Box, Stack } from "@mui/system";
import React from "react";
import Div from "../Components/Layouts/Div";
import aboutHero from "../assets/images/aboutHero.webp";
import aboutHeroMobile from "../assets/images/aboutHeroMobile.webp";
import idsServices from "../store/IdsServices";
import AboutCard from "../Components/card/AboutCard";
import img1 from "../assets/images/about-us/iaqg.webp";
import img2 from "../assets/images/about-us/as.webp";
import img3 from "../assets/images/about-us/iso.webp";
import img4 from "../assets/images/about-us/hippa.webp";
import img5 from "../assets/images/about-us/isoglobe.webp";
import Journey from "../Components/card/Journey";
import Testimonials from "../Components/Testimonials";
import ContactUsAbout from "../Components/card/ContactUsAbout";
import JointVentures from "../Components/JointVentures";
import GoToTop from "../Components/GoToTop";
import withHelmet from "../Components/hoc/withHelmet";

const About = () => {
  return (
    <Stack>
      <Div bgColor="primary.blue50">
        <Stack
          maxWidth={"xl"}
          mx={"auto"}
          mt={{ xs: 6, md: 8 }}
          display={"flex"}
          direction={{ xs: "column-reverse", md: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={{ xs: "center", md: "initial" }}
        >
          <Stack
            display={"flex"}
            textAlign={{ xs: "center", md: "initial" }}
            sx={{ placeContent: "center" }}
          >
            <Typography
              color={"primary.blue900"}
              sx={{ typography: { xs: "displayMdSemi", md: "displayXlBold" } }}
            >
              Rethink Digital Publishing...{" "}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textLgreg", md: "textXlReg" },
                color: "primary.blue700",
                maxWidth: "sm",
                my: 2,
                mb: { xs: 6, md: "initial" },
              }}
            >
              Leveraging more than three decades of experience to assist
              publishers and content aggregators in reimagining digital
              publishing
            </Typography>
          </Stack>

          <Box
            display={{ xs: "initial", md: "none" }}
            component={"img"}
            mb={4}
            mr={{ xs: 0, md: 15 }}
            src={aboutHeroMobile}
            maxWidth={{ xs: "15rem", md: "35rem" }}
          />
          <Box
            component={"img"}
            display={{ xs: "none", md: "initial" }}
            mr={{ xs: 0, md: 15 }}
            src={aboutHero}
            maxWidth={{ xs: "20rem", md: "35rem" }}
          />
        </Stack>
      </Div>

      <Div>
        <Stack maxWidth={"xl"} mx={"auto"} my={6}>
          <Typography
            sx={{ typography: { xs: "displaySmSemi", md: "displayMdSemi" } }}
            my={2}
          >
            Overview
          </Typography>
          <Typography
            sx={{
              typography: { xs: "textMdReg", md: "textLgReg" },
            }}
          >
            IDS provides customized and technology-driven services to help
            clients digitize, convert electronic content, and offer editorial
            and pre-press solutions. We work closely with our clients every day
            to deliver intelligent and interactive digital content through
            strategic publishing and content conversion. Our team of over 200
            professionals is dedicated to content management and publishing
            services, and we've been providing optimized solutions to clients in
            the US and Europe. We have a proven track record of meeting and
            exceeding client requirements for content and publishing solutions.
            <br></br> <br></br> Our ISO 9001:2015 and ISO 27001 certifications
            ensure that we deliver the highest standards of quality as per
            agreed service level requirements. We use a data-intensive,
            process-oriented approach to all projects, following Six Sigma
            (DMAIC) and LEAN techniques and operating under Statistical Quality
            Control (SQC) checkpoints.
          </Typography>
        </Stack>
      </Div>
      <Stack my={8}>
        <Stack className={"ids"}>
          <Div bgColor={"transparent"}>
            <Stack maxWidth={"xl"} mx={"auto"} spacing={2} py={6}>
              <Typography
                sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
                color="primary.primary600"
              >
                About IDS
              </Typography>
              <Typography
                sx={{
                  typography: { xs: "displayMdSemi", md: "displayLgSemi" },
                }}
                color="grey.grey900"
              >
                Transforming Organizations Worldwide with AI-enabled Digital
                Solutions
              </Typography>

              <Typography
                pt={2}
                sx={{
                  typography: { xs: "textMdReg", md: "textLgReg" },
                }}
              >
                Established in 1989, IDS Infotech Ltd. is built on the legacy of
                providing customized solutions to professionals and business
                houses across different industries, enabling them to optimize
                their business processes and achieve excellence and higher
                productivity.
                <br></br>
                <br></br>
                As a preferred supplier to Fortune 500 companies, IDS boasts a
                strong retention rate and has a long-term association with over
                90% of its clients. IDS has a highly skilled and experienced
                leadership, and core team having an average association of 15+
                years.
                <Stack my={2} sx={{ p: 0 }}>
                  <a
                    target="_blank"
                    href="https://idsil.com/about.php"
                    style={{ width: "max-content", padding: "0px" }}
                    rel="noreferrer noopener"
                  >
                    <Typography
                      color={"primary.blue700"}
                      sx={{
                        typography: { xs: "textMdSemi", md: "textLgSemi" },
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Learn more about IDS
                    </Typography>
                  </a>
                </Stack>
              </Typography>
            </Stack>
          </Div>
        </Stack>
        <Stack mx={"auto"} maxWidth={"xl"} my={6}>
          <Div>
            <Grid
              container
              rowSpacing={{ xs: 4, sm: 4, md: 6 }}
              spacing={{ sm: 4, md: 6 }}
            >
              {idsServices.map((service, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <Stack sx={{ height: "100%" }}>
                    <AboutCard
                      title={service.title}
                      description={service.description}
                      link={service.link}
                    />
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Div>
        </Stack>
      </Stack>

      <Journey />
      <JointVentures />
      <Stack mt={0} mb={9}>
        <Container
          sx={[
            { border: "1px solid" },
            { borderColor: "primary.gray100" },

            { borderRadius: "12px" },
            {
              boxShadow:
                " 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);",
            },
          ]}
        >
          <Stack
            direction={{ md: "column", sm: "column" }}
            justifyContent="space-between"
            py={4}
          >
            <Stack>
              <Typography
                textAlign={"center"}
                mb={{ xs: 4, sm: 4, md: 4 }}
                sx={{
                  typography: {
                    xs: "displaySmSemi",
                    sm: "displayMdSemi",
                    md: "displayMdSemi",
                  },
                }}
                color="grey.grey900"
              >
                Certifications and Compliances
              </Typography>
            </Stack>
            <Stack
              display={"flex"}
              direction={{ md: "row" }}
              justifyContent={"center"}
              spacing={{ md: 10 }}
            >
              <Stack width={{ md: "10%" }} height={{ md: "10%" }}>
                <img
                  src={img1}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "fill" }}
                  alt="certificate"
                />
              </Stack>
              <Stack width={{ md: "10%" }} height={{ md: "10%" }}>
                <img
                  src={img2}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "fill" }}
                  alt="certificate"
                />
              </Stack>

              <Stack width={{ md: "10%" }} height={{ md: "10%" }}>
                <img
                  src={img3}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "fit" }}
                  alt="certificate"
                />
              </Stack>
              <Stack width={{ md: "10%" }} height={{ md: "10%" }}>
                <img
                  src={img4}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "fit" }}
                  alt="certificate"
                />
              </Stack>

              <Stack width={{ md: "10%" }} height={{ md: "10%" }}>
                <img
                  src={img5}
                  width={"100%"}
                  height={"100%"}
                  style={{ objectFit: "fit" }}
                  alt="certificate"
                />
              </Stack>
            </Stack>
          </Stack>
        </Container>
        <Stack maxWidth={"xl"} mx={"auto"}></Stack>
      </Stack>
      <Testimonials />
      <ContactUsAbout />
      <GoToTop />
    </Stack>
  );
};

const helmetConfig = {
  htmlLang: "en",
  title: "About",
  metaDescription: "First Page",
  metaThemeColor: "#E6E6FA",
  metaTags: [{ name: "author", content: "Your Name" }],
};

export default withHelmet(About, helmetConfig);
