import React from "react";
import CaseStudyCard from "../card/CaseStudyCard";
import CaseStudyCardData from "../../store/CaseStudyCardData";
import { Stack } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import "../../assets/images/style.css";

const MobileCaseStudySection = () => {
  const params = useParams();
  return (
    <>
      <Stack>
        <Stack direction="row" spacing={4} overflow={"auto"}>
          {CaseStudyCardData.filter(
            (caseStudy) => caseStudy.type !== params.type
          ).map((CaseStudyValue, index) => (
            <Stack
              Item
              sx={[
                { position: "relative" },
                { width: "80%" },
                { height: "100%" },
                { borderRadius: "20px" },
              ]}
              flexShrink={0}
              className="caseStudy_Card_Mobile"
            >
              <Link
                to={`/case-study/${CaseStudyValue.type}`}
                style={{ textDecoration: "none" }}
              >
                <CaseStudyCard
                  className="caseStudy_Card_Content_Mobile"
                  Heading={CaseStudyValue.Heading}
                  SvgIcons={CaseStudyValue.SvgIcons}
                  SubHeading={CaseStudyValue.SubHeading}
                  Para={CaseStudyValue.Paragraph}
                />
              </Link>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </>
  );
};
export default MobileCaseStudySection;
