import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import "../assets/images/style.css";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
// import { Stack } from "@mui/system";
const subHeading = {
  fontSize: { xs: "14px", md: "16px" },
  color: "primary.blue700",
  padding: "0",
  mt: 2,
};

export default function BlogCard(props) {
  return (
    <Card
      elevation={1}
      sx={{
        // maxWidth: "592px",
        // height: { xs: "390px", md: "450px", lg: "415px" },
        // my: "12px",
        padding: "0px",
        position: "relative",
        borderRadius: "16px",
        "&:hover": {
          boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
          cursor: "pointer",
        },
      }}
    >
      <CardMedia
        component="img"
        sx={{ height: { xs: "150px", md: "200px" } }}
        // height={{ xs: "288px", md: "288px" }}
        image={props.thumbnail}
        alt="green iguana"
      />

      <CardContent>
        <Typography
          gutterBottom
          mb={3}
          className="heading_class"
          sx={{ typography: { xs: "textXlSemi", md: "displayXsSemi" } }}
          component="div"
        >
          {props.name}
        </Typography>
        <Typography
          sx={{
            typography: "textMdReg",
          }}
          className="content_class"
          color="text.secondary"
        >
          {props.content}
        </Typography>
        <Typography>
          <Button size="small" sx={subHeading}>
            Read post {"  "}
            <ArrowOutwardIcon
              color="primary.blue700"
              fontSize="8px"
              sx={{ mx: "5px" }}
            />
          </Button>
        </Typography>
      </CardContent>
    </Card>
  );
}
