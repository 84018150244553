import React from "react";
import { Stack } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Ceo from "../../assets/images/ceo.webp";
import Div from "../Layouts/Div";

const CeoSection = () => {
  return (
    <Div>
      <Stack mx={"auto"} maxWidth={"xl"} my={20}>
        <Grid
          container
          bgcolor="primary.blue700"
          width="100%"
          height={"100%"}
          position="relative"
          borderRadius="14px"
          direction={{ md: "row" }}
        >
          <Grid item md={7} sm={12}>
            <Stack
              color="white"
              width={{ md: "100%" }}
              p={{ xs: "1.8rem", md: "1.2rem", xl: "2.1rem" }}
            >
              <Typography
                sx={{
                  typography: "textLgReg",
                  textAlign: "left",
                }}
              >
                Data is only useful when it is properly structured and enhanced
                to meet specific objectives. This is where the expertise of our
                team comes in. By collaborating with industry experts and
                technology specialists, we can identify the most effective
                process flows and map them to the capabilities of today's
                rapidly advancing technology. This allows us to develop
                innovative solutions that meet the evolving needs of our
                audience.
                <br />
                <br />
                As an agile content management and publishing company, we are
                committed to staying ahead of the curve and providing our
                clients with the best possible service. We believe that the
                future is bright and that our innovative solutions will continue
                to make a real difference in the lives of our clients.
              </Typography>
              <Stack
                mt={4}
                sx={{
                  position: { xs: "absolute", md: "initial" },
                  bottom: "0px",
                  mx: { xs: "-1.8rem", md: "initial" },
                  borderRadius: "0px 10px 10px 0px",
                  backgroundColor: {
                    xs: "rgba(0, 0, 0, 0.62)",
                    md: "transparent",
                  },
                  px: { xs: 2, md: "initial" },
                }}
              >
                <Typography
                  sx={{
                    typography: "textLgSemi",
                  }}
                >
                  — Partap Krishan Aggarwal
                </Typography>
                <Typography sx={{ typography: "textSmReg" }}>
                  Founder and CEO, IDS Infotech
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={5} sm={12}>
            <Stack
              sx={{
                position: { md: "absolute" },
                bottom: "0px",
                right: "0px",
                borderRadius: "14px",
              }}
              width={{ md: "400px" }}
            >
              <img
                src={Ceo}
                alt="CEO"
                width="100%"
                height="100%"
                style={{ borderRadius: "14px" }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Div>
  );
};

export default CeoSection;
