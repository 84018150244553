import { Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CaseStudyInternalData from "../../store/CasesStudyPagesData.json";
import ListedCard from "./ListedCard";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import React from "react";
import GoToTop from "../GoToTop";
import { AiOutlineCheckCircle } from "react-icons/ai";
import CaseStudySection from "../home/CaseStudySection";
import Div from "../Layouts/Div";

const CaseStudyInternalPageCard = () => {
  const { type } = useParams();
  const [CaseStudy, setCaseStudy] = useState({});
  useEffect(() => {
    setCaseStudy(CaseStudyInternalData[type]);
  }, [type]);
  let content = "";

  if (Object.keys(CaseStudy).length === 0) {
    content = <h1>Loading...</h1>;
  }

  if (Object.keys(CaseStudy).length > 0) {
    content = (
      <>
        <Stack>
          {/* Banner_section */}
          <Stack>
            <Div bgColor={"primary.blue700"}>
              <Stack mx={"auto"} maxWidth={"xl"}>
                <Stack
                  bgcolor={"primary.primary700"}
                  color={"white"}
                  py={8}
                  sx={{ px: { xs: "2rem", sm: "2rem", md: "3rem" } }}
                  direction={{ xs: "column", md: "row" }}
                  textAlign={{ xs: "center", sm: "center", md: "left" }}
                >
                  <Grid container>
                    <Grid
                      Item
                      py={{ xs: "4rem", sm: "4rem", md: "8rem" }}
                      lg={7}
                      sm={12}
                      spacing={{ xs: 2, md: 3 }}
                    >
                      <Typography
                        sx={{
                          typography: "textMdSemi",
                          color: "primary.blue100",
                        }}
                      >
                        {CaseStudy.bannerSubheading}
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          typography: "displayLgSemi",
                          my: "1rem",
                        }}
                      >
                        {CaseStudy.bannerHeading}
                      </Typography>
                      <Typography
                        sx={{ color: "white", typography: "textMdReg" }}
                      >
                        {CaseStudy.bannerParagraph}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Div>
          </Stack>
          <Stack>
            {/* firstsection */}
            <Div bgColor="primary.gray50">
              <Stack
                bgcolor="primary.gray50"
                sx={{ px: { xs: "2rem", sm: "2rem", md: "3rem" } }}
              >
                <Stack mx={"auto"} maxWidth={"xl"}>
                  <Grid container direction="row" py={8}>
                    <Grid Item lg={6} sm={12}>
                      <Typography
                        typography={"displayMdSemi"}
                        sx={{ position: "sticky", top: "8rem" }}
                      >
                        {CaseStudy.client}
                      </Typography>
                    </Grid>
                    <Grid Item lg={6} px={2}>
                      <Typography sx={{ typography: "textXlSemi", my: "1rem" }}>
                        {CaseStudy.heading}
                      </Typography>
                      <Stack direction="row" spacing={2}>
                        <Typography>
                          <AiOutlineCheckCircle />
                        </Typography>
                        <Typography sx={{ typography: { md: "textXlReg" } }}>
                          {CaseStudy.clientSolution}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Div>
            {/* secondsection */}
            <Div>
              <Stack sx={{ px: { xs: "2rem", sm: "2rem", md: "3rem" } }}>
                <Stack mx={"auto"} maxWidth={"xl"}>
                  <Grid container direction="row" py={8}>
                    <Grid Item lg={6}>
                      <Typography
                        typography={"displayMdSemi"}
                        sx={{ position: "sticky", top: "8rem" }}
                      >
                        {CaseStudy.clientRequirement}
                      </Typography>
                    </Grid>
                    <Grid Item lg={6}>
                      <Grid container direction="row">
                        <Grid Item lg={12}>
                          {CaseStudy.clientRequirementAns.map(
                            (ListValue, index) => (
                              <ListedCard
                                key={index}
                                listText={ListValue.requirePoint}
                              />
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Div>

            {/* thirdsection */}
            <Div bgColor="primary.gray50">
              <Stack
                bgcolor="primary.gray50"
                sx={{ px: { xs: "2rem", sm: "2rem", md: "3rem" } }}
              >
                <Stack mx={"auto"} maxWidth={"xl"}>
                  <Grid container direction="row" py={8}>
                    <Grid Item lg={6}>
                      <Typography
                        typography={"displayMdSemi"}
                        sx={{ position: "sticky", top: "8rem" }}
                      >
                        {CaseStudy.ourSolution}
                      </Typography>
                    </Grid>
                    <Grid Item lg={6}>
                      <Grid container direction="row">
                        <Grid Item lg={12}>
                          {CaseStudy.ourSolutionAns.map((ListValue, index) => (
                            <ListedCard
                              key={index}
                              listText={ListValue.requirePoint}
                            />
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Div>

            {/* fourth section */}
            <Div>
              <Stack px={{ xs: "2rem", sm: "2rem", md: "3rem", xl: "10rem" }}>
                <Stack mx={"auto"} maxWidth={"xl"}>
                  <Grid container direction="row" py={8}>
                    <Grid Item lg={6}>
                      <Typography
                        typography={"displayMdSemi"}
                        sx={{ position: "sticky", top: "8rem" }}
                      >
                        {CaseStudy.bussinessResults}
                      </Typography>
                    </Grid>
                    <Grid Item lg={6}>
                      <Grid container direction="row">
                        <Grid Item lg={12}>
                          {CaseStudy.businessResultAns.map(
                            (ListValue, index) => (
                              <ListedCard
                                key={index}
                                listText={ListValue.requirePoint}
                              />
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Div>
          </Stack>
        </Stack>
        <Stack mx={6} mb={8}>
          <Divider></Divider>
        </Stack>
        <CaseStudySection />
        <GoToTop />
      </>
    );
  }
  return content;
};

export default CaseStudyInternalPageCard;
