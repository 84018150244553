import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import Div from "./Layouts/Div";
export default function BlogContent(props) {
  const contentStyle = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#475467",
  };
  return (
    <Div>
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component={"img"}
          src={props.image}
          sx={{
            position: "relative",
            borderRadius: "1rem",
            top: "-150px",
            width: "100%",
          }}
        />
        <Stack maxWidth={"md"} mb={6} mt={-10} spacing={3}>
          <div
            dangerouslySetInnerHTML={{ __html: `${props.content}` }}
            style={contentStyle}
          ></div>
          <Typography
            sx={{
              typography: { xs: "textMdReg", md: "textLgReg" },
              color: "primary.gray600",
            }}
          ></Typography>
        </Stack>
      </Stack>
    </Div>
  );
}
