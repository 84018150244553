import React from "react";
import Div from "./Layouts/Div";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import blogImg1 from "../assets/images/Svg/Ideas.svg";
import blogImg2 from "../assets/images/Svg/Argus.svg";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
function JointVentures() {
  const subHeading = {
    fontSize: { xs: "14px", md: "16px" },
    color: "primary.blue700",
    padding: "0",
    mt: 2,
  };

  return (
    <Div>
      {" "}
      <Stack
        my={8}
        spacing={2}
        maxWidth={"xl"}
        mx={"auto"}
        // display="flex"
        // direction={{ xs: "column", md: "row" }}
      >
        <Stack>
          <Typography
            sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
            color="primary.blue600"
          >
            Expanding Horizons
          </Typography>
          <Typography
            sx={{ typography: { xs: "displayMdSemi", md: "displayLgSemi" } }}
            color="primary.gray900"
          >
            Our Joint Ventures
          </Typography>
          <Stack
            display={"flex"}
            justifyContent={"space-between"}
            direction={{ xs: "column", md: "row" }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              display="flex"
              py={3}
              px={3}
              // alignItems="center"
              spacing={5}
            >
              <Stack maxWidth={"500px"} spacing={2} py={2}>
                <Stack spacing={2}>
                  <Box
                    component={"img"}
                    maxWidth={"300px"}
                    py={2}
                    src={blogImg2}
                  />

                  <Typography
                    sx={{ typography: "textMdReg", color: "primary.gray600" }}
                  >
                    Established in 2012, IDS-Argus Healthcare Services Private
                    Limited is a joint venture between IDS Infotech Limited
                    (India) and Argus Holding Company LLC (USA). Co-management
                    arrangement since 2008, officially a joint venture in 2012
                  </Typography>
                </Stack>
                <Typography>
                  <Button
                    href="http:www.idsargus.com/"
                    size="small"
                    sx={subHeading}
                  >
                    Read more {"  "}
                    <ArrowOutwardIcon
                      color="primary.blue700"
                      fontSize="8px"
                      sx={{ mx: "5px" }}
                    />
                  </Button>
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              display="flex"
              py={3}
              px={3}
              // alignItems="center"
              spacing={5}
            >
              <Stack maxWidth={"500px"} spacing={2} py={2}>
                <Stack spacing={2}>
                  <Box
                    component={"img"}
                    maxWidth={"300px"}
                    py={2}
                    src={blogImg1}
                  />

                  <Typography
                    sx={{ typography: "textMdReg", color: "primary.gray600" }}
                  >
                    Inde Dutch Engineering & Aerospace services Limited is a
                    leading engineering solutions & services provider catering
                    to customers in the aerospace, automotive, machinery, and
                    affiliated industry segments.
                  </Typography>
                </Stack>
                <Typography>
                  <Button
                    href="http://www.ideasengg.com/"
                    size="small"
                    sx={subHeading}
                  >
                    Read more {"  "}
                    <ArrowOutwardIcon
                      color="primary.blue700"
                      fontSize="8px"
                      sx={{ mx: "5px" }}
                    />
                  </Button>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Div>
  );
}

export default JointVentures;

//  <Stack
//    my={"6rem"}
//    spacing={2}

//  >

//    <Typography
//      sx={{ typography: { xs: "displayMdSemi", md: "displayLgSemi" } }}
//      color="primary.gray900"
//    >
//      Joint Ventures
//    </Typography>
//    <Stack
//      direction={{ xs: "column", lg: "row" }}
//      display="flex"
//      justifyContent={"center"}
//      py={3}
//      alignItems="center"
//      spacing={5}
//    >
//      <Stack
//        direction={{ xs: "column", md: "row" }}
//        display={"flex"}
//        justifyContent="space-between"
//        alignItems={"center"}
//        spacing={2}
//        py={2}
//      >
//        <Box component={"img"} maxWidth={"300px"} src={blogImg1} />

//        <Stack>
//          <Typography
//            maxWidth={"md"}
//            sx={{ typography: "textMdReg", color: "primary.gray600" }}
//          >
//            Established in 2012, IDS-Argus Healthcare Services Private
//            Limited is a joint venture between IDS Infotech Limited (India)
//            and Argus Holding Company LLC (USA). Co-management arrangement
//            since 2008, officially a joint venture in 2012
//          </Typography>
//          <Typography>
//            <Button
//              href="http://www.idsargus.com/"
//              size="small"
//              sx={subHeading}
//            >
//              Read more {"  "}
//              <ArrowOutwardIcon
//                color="primary.blue700"
//                fontSize="8px"
//                sx={{ mx: "5px" }}
//              />
//            </Button>
//          </Typography>
//        </Stack>
//      </Stack>
//      <Stack
//        direction={{ xs: "column", md: "row" }}
//        display={"flex"}
//        justifyContent="space-between"
//        alignItems={"center"}
//        spacing={2}
//        py={2}
//      >
//        <Box component={"img"} maxWidth={"300px"} src={blogImg1} />

//        <Stack>
//          <Typography
//            maxWidth={"md"}
//            sx={{ typography: "textMdReg", color: "primary.gray600" }}
//          >
//            Inde Dutch Engineering & Aerospace services Limited is a leading
//            engineering solutions & services provider catering to customers
//            in the aerospace, automotive, machinery, and affiliated industry
//            segments.
//          </Typography>
//          <Typography>
//            <Button
//              href="http://www.idsargus.com/"
//              size="small"
//              sx={subHeading}
//            >
//              Read more {"  "}
//              <ArrowOutwardIcon
//                color="primary.blue700"
//                fontSize="8px"
//                sx={{ mx: "5px" }}
//              />
//            </Button>
//          </Typography>
//        </Stack>
//      </Stack>
//    </Stack>
//  </Stack>;
