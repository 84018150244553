import React, { useState } from "react";
import Toast from "../../utils/Toast";
import useApi from "../../hooks/use-api";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const Subscribe = (props) => {
  const initialValue = { email: "", checkField: "" };
  const [formValue, setFormValue] = useState(initialValue);
  const [emailError, setEmailError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const emailValueHandler = (e) => {
    const { name, value } = e.target;
    setFormValue({ [name]: value });
    setEmailError(emailValidationHandler(formValue));
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    const error = emailValidationHandler(formValue);
    if (Object.keys(error).length === 0) {
      setIsLoading(true);
      formValue.type = props.type;
      const result = await useApi.postRequest(
        "subscribers",
        false,
        formValue,
        "REACT_APP_API_KEY_SUBSCRIBER"
      );
      setIsLoading(false);
      if (result.status === "1") {
        setFormValue({ email: "" });
        return Toast.fire({ icon: "success", title: result.message });
      }
      return Toast.fire({ icon: "error", title: result.message });
    }

    return setEmailError(error);
  };

  const emailValidationHandler = (values) => {
    const errors = {};
    const regex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!values.email) {
      errors.email = "Please write your email";
    } else if (!regex.test(values.email)) {
      errors.email = "Email is not valid";
    }
    return errors;
  };

  return (
    <form onSubmit={handlerSubmit}>
      <Stack item="true">
        <Stack
          direction={{ md: "row", sm: "column" }}
          spacing={{ xs: 2, sm: 2, md: 4 }}
        >
          <TextField
            size="small"
            placeholder="Enter your email"
            name="email"
            value={formValue.email}
            onChange={emailValueHandler}
            onBlur={emailValueHandler}
            InputProps={{
              sx: {
                padding: "4px",
                "&.Mui-focused": {
                  backgroundColor: "primary.white",
                  border: "1px solid ",
                  borderColor: "primary.blue600",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                },
              },
            }}
          ></TextField>
          <input
            type="text"
            value={formValue.checkField}
            onChange={emailValueHandler}
            style={{ display: "none" }}
          />
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              type="submit"
              variant="contained"
              py={1}
              sx={[
                {
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 20px",
                  lineHeight: "24px",
                },
                {
                  "&:hover": {
                    bgcolor: "primary.blue700",
                  },
                },
              ]}
              disableElevation
              disabled={isLoading}
            >
              Subscribe
            </Button>
            {isLoading && (
              <CircularProgress
                color="secondary"
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Stack>
        <Typography sx={{ color: "red" }}>{emailError.email}</Typography>
      </Stack>
    </form>
  );
};

export default Subscribe;
