import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ClientCards from "../card/ClientCards";
import ClientsCardData from "../../store/AboutCardData";
import Div from "../Layouts/Div";

const Journey = () => {
  return (
    <Stack mb={8} bgcolor="primary.gray100">
      <Div bgColor={"transparent"} py={{ xs: 6, sm: 6, md: 6 }}>
        <Stack mx={"auto"} maxWidth={"xl"}>
          <Stack
            container
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Stack Item sx={{ maxWidth: { xs: "100%", md: "60%" } }}>
              <Typography
                pb={4}
                sx={{
                  typography: { xs: "displaySmSemi", md: "displayMdSemi" },
                }}
              >
                Key Highlights
              </Typography>
            </Stack>
            <Stack Item my={{ xs: 3, sm: 3.5, md: 0 }}>
              <a
                target="_blank"
                href="https://idsil.com/contact-us/"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <Button
                  size="medium"
                  sx={[
                    {
                      color: "white",
                      bgcolor: "primary.blue600",
                      fontSize: "16px",
                      fontWeight: "600",
                      padding: "12px 20px",
                      lineHeight: "24px",
                      width: "100%",
                    },
                    {
                      "&:hover": {
                        bgcolor: "primary.primary700",
                      },
                    },
                  ]}
                >
                  Get in touch
                </Button>
              </a>
            </Stack>
          </Stack>
        </Stack>
        <Stack mx={"auto"} maxWidth={"xl"}>
          <Grid container spacing={{ md: "2rem", xl: "4rem" }}>
            {ClientsCardData.map((ClientValue, index) => (
              <Grid Item xs={12} sm={6} md={3} key={index}>
                <ClientCards
                  Heading={ClientValue.Heading}
                  Mainheading={ClientValue.Mainheading}
                  Subheading={ClientValue.Subheading}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Div>
    </Stack>
  );
};

export default Journey;
