import React from "react";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import "../../assets/images/style.css";
const ServiceCard = (props) => {
  return (
    <div>
      <Stack>
        <Stack
          sx={{
            backgroundColor: "#132f4c",
            px: "1rem",
            py: "1rem",
            color: "white",
            borderRadius: "16px",
            width: "100%",
            height: "370px",
            border: "2px solid #1e4976",
            "&:hover": {
              borderColor: "primary.blue600",
              // border:"2px solid  primary.blue100",
            },
          }}
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
          className="Service_card_Container"
        >
          <Stack Item>
            <Typography
              sx={{
                typography: { xs: "textXlSemi", md: "displayXsBold" },
                pb: "1rem",
              }}
              Wrap
            >
              {props.Title}
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textLgReg", md: "textLgReg" },
                color: "primary.gray300",
              }}
            >
              {props.Paragraph}
            </Typography>
            {/* <Typography
            sx={{ typography: { md: "textLgSemi" }, color: "primary.gray600" }}
          >
            {props.Action1}
          </Typography> */}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default ServiceCard;
