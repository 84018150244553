import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CaseStudyCard from "../card/CaseStudyCard";
import CaseStudyCardData from "../../store/CaseStudyCardData";
import nextArrow from "../../assets/images/Svg/testimonial_left.svg";
import previousArrow from "../../assets/images/Svg/testimonial_right.svg";
import { Link, useParams } from "react-router-dom";
// import * as Scroll from "react-scroll";
import "../../assets/images/style.css";

const ButtonGroup = ({ goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
    length,
  } = rest;

  const disabledNextButtonLength = () => {
    if (window.screen.width >= 1024) {
      return length - 3;
    }

    if (window.screen.width >= 768) {
      return length - 2;
    }

    if (window.screen.width >= 230) {
      return length - 1;
    }
  };

  return (
    <div className="carousel-button-group">
      <Stack direction="row" my={4} sx={{ color: "blue" }}>
        <Button
          variant="circular"
          onClick={() => goToSlide(currentSlide - 1)}
          sx={{
            color: "black",
            borderRadius: "50%",
            width: "90px",
            height: "90px",
            visibility: "",
          }}
          disabled={currentSlide === 0}
        >
          {/* Go to any slide-1 */}
          <img
            src={previousArrow}
            alt="left Arrow"
            width="100%"
            height="100%"
          />
        </Button>
        <Button
          variant="circular"
          onClick={() => goToSlide(currentSlide + 1)}
          sx={{ color: "black", borderRadius: "50%", width: "90px" }}
          disabled={currentSlide === disabledNextButtonLength() || length <= 3}
        >
          <img src={nextArrow} alt="Next Arrow" width="100%" height="100%" />
        </Button>
      </Stack>
    </div>
  );
};

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3.5,
    slidesToSlide: 3.5, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 640, min: 320 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const MultiCarousel = (props) => {
  const params = useParams();

  return (
    <>
      <Stack>
        <Carousel
          responsive={responsive}
          arrows={false}
          renderButtonGroupOutside={true}
          containerClass="carousel-container"
          customButtonGroup={<ButtonGroup length={CaseStudyCardData.length} />}
          // infinite={true}
        >
          {CaseStudyCardData.filter(
            (caseStudy) => caseStudy.type !== params.type
          ).map((CaseStudyValue, index) => (
            <Stack direction="row">
              <Stack
                mx={"1rem"}
                sx={[
                  { position: "relative" },

                  { height: "100%" },
                  { borderRadius: "20px" },
                ]}
                className="caseStudy_Card"
              >
                <Link
                  to={`/case-study/${CaseStudyValue.type}`}
                  style={{ textDecoration: "none" }}
                >
                  <CaseStudyCard
                    className="caseStudy_Content caseStudy_CardPara"
                    Heading={CaseStudyValue.Heading}
                    SvgIcons={CaseStudyValue.SvgIcons}
                    SubHeading={CaseStudyValue.SubHeading}
                    Para={CaseStudyValue.Paragraph}
                  />
                </Link>
              </Stack>
            </Stack>
          ))}
        </Carousel>
      </Stack>
    </>
  );
};

export default MultiCarousel;
