const ServiceCardData=[
    {
        Title:"Multilingual Services",
        Paragraph:"Multilingual content writing and management services, including metadata creation, full-text conversion, content conversion for online newsstand services, and Abstracting and Indexing.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Prepress Services ",
        Paragraph:"We provide technology-driven solutions and cover the entire prepress spectrum, including editorial, composition, typesetting, and project management services. ",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Abstracting and Indexing ",
        Paragraph:"We offer professional Abstracting and Indexing Services for journals and magazines.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Data Annotation",
        Paragraph:"We specialize in high-quality data labelling and annotation services and preparing training datasets for AI models.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Transcription ",
        Paragraph:"We cater to the transcription needs of academic and enterprise clients.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Metrics and Analysis Support ",
        Paragraph:"We empower clients with actionable business insights through our Metrics and Analysis Support Services.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Support Services: CMS, RPA, and AI ",
        Paragraph:"We support publishing and research information service providers through cutting-edge CMS, RPA, and AI Support Services.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Content Management / Maintenance",
        Paragraph:"We assist clients with content workflow, web tracking systems, and management/maintenance.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Electronic Data Capture",
        Paragraph:"We capture relevant information from any media into text or MSWORD files with 99.995% accuracy.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"Electronic Data Conversion ",
        Paragraph:"We offer a wide range of electronic conversion solutions to support clients by providing a consistent structure to their content.",
        Action1:"Share",
        Action2:"LearnMore",

    },
    {
        Title:"E-publishing",
        Paragraph:"We specialize in redefining and redistributing your content across all e-publishing business models — from eBooks to e-articles and online digital libraries.",
        Action1:"Share",
        Action2:"LearnMore",

    },
]
export default ServiceCardData