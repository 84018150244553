import {
  AppBar,
  Button,
  Toolbar,
  // Menu,
  // MenuItem,
  SwipeableDrawer,
  IconButton,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../assets/images/Svg/logo.svg";
import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "../../assets/images/style.css";
import classes from "../../assets/images/Navbar.module.css";
import Div from "./Div";
import { NavHashLink } from "react-router-hash-link";

const buttonStyle = [
  {
    color: "primary.gray600",
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 20px",
    lineHeight: "24px",
  },
  {
    "&:hover": {
      bgcolor: "primary.gray100",
      color: "primary.blue700",
      cursor: "pointer",
    },
  },
];

// const buttonStyleMenu = [
//   {
//     color: "primary.gray600",
//     fontSize: "16px",
//     fontWeight: "600",
//     padding: "12px 20px",
//     lineHeight: "24px",
//   },
//   {
//     "&:hover": {
//       bgcolor: "primary.gray100",
//       color: "primary.blue700",
//       cursor: "pointer",
//       scale: "1.1",
//     },
//   },
// ];
export default function Navbar({ toggleMode, setToggleMode }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const [anchorEl, setAnchorEl] = useState({
  //   anchorEl1: null,
  //   anchorEl2: null,
  //   anchorEl3: null,
  //   anchorEl4: null,
  // });
  // const [dropdown, setDropdown] = useState(false);
  // const openMenu = Boolean(anchorEl.anchorEl1);
  // const handleClick = (e) => {
  //   setAnchorEl({ ...anchorEl, anchorEl1: e.currentTarget });
  // };
  // const handleDropdown = () => {
  //   setDropdown(!dropdown);
  // };
  // const handleClose = (e) => {
  //   setAnchorEl({ ...anchorEl, anchorEl1: null });
  // };
  const [open, setOpen] = useState(false);

  return (
    <AppBar
      sx={{
        position: "fixed",
        boxShadow: "none",

        width: "100%",

        backgroundColor: "primary.main",
      }}
    >
      <Div bgColor="primary.main">
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            padding: "0px 0px",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: "xl",
            mx: "auto",
          }}
        >
          <Stack display={"flex"} flexDirection="row">
            <Stack
              display={"flex"}
              alignSelf="center"
              sx={{ cursor: "pointer" }}
            >
              <NavLink to="/">
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "65px", verticalAlign: "middle" }}
                />
              </NavLink>
            </Stack>
          </Stack>
          <Stack
            sx={{
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              direction: "initial",
              justifyContent: "center",
              alignItems: "center",
              padding: "0px",
              fontSize: "16px",
              gap: "8px",
            }}
          >
            <NavLink
              to="/about-us"
              style={{
                textDecoration: "none",
                // color: "#F2F4F7",
              }}
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
            >
              <Button size="medium" sx={buttonStyle}>
                About Us
              </Button>
            </NavLink>
            <NavHashLink
              to="/#services"
              style={{
                textDecoration: "none",
                // color: "#F2F4F7",
              }}
            >
              <Button size="medium" sx={buttonStyle}>
                Services
              </Button>
            </NavHashLink>
            {/* <Link> */}
            {/* <Button
              size="medium"
              id="product"
              aria-controls="product"
              aria-haspopup="true"
              aria-expanded={openMenu ? "true" : undefined}
              onClick={handleClick}
              onMouseOver={handleClick}
              sx={buttonStyle}
              style={{ cursor: "pointer" }}
            >
              Services
            </Button> */}
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
              to="/blogs"
              style={{ textDecoration: "none" }}
            >
              <Button size="medium" sx={buttonStyle}>
                Blogs
              </Button>
            </NavLink>
            <NavLink
              to="/case-study"
              className={({ isActive }) =>
                isActive ? classes.active : classes.inactive
              }
              style={{ textDecoration: "none" }}
            >
              <Button size="medium" sx={buttonStyle}>
                Case Studies
              </Button>
            </NavLink>

            {/* aria-controls must be same to Menu id */}
            {/* <Menu
              id="product"
              open={openMenu}
              anchorEl={anchorEl.anchorEl1}
              onClose={handleClose}
              MenuListProps={{
                style: { padding: 0 },
                onMouseLeave: handleClose,
                // onMouseOver: handleClick,
              }}
            >
              <Stack p={3} spacing={2}>
                <a
                  href={"https://ideasengg.com/en_US/"}
                  style={{ all: "unset" }}
                >
                  <MenuItem sx={buttonStyleMenu}>
                    {" "}
                    Engineering Services
                  </MenuItem>
                </a>
                <a
                  href={"https://ids-technologies.in/"}
                  style={{ all: "unset" }}
                >
                  <MenuItem sx={buttonStyleMenu}>Software Services</MenuItem>
                </a>
                <a
                  href={"http://healthcare.idsil.com/"}
                  style={{ all: "unset" }}
                >
                  <MenuItem sx={buttonStyleMenu}>Healthcare Services</MenuItem>
                </a>
                <a href={"https://ehs.idsil.com/"} style={{ all: "unset" }}>
                  <MenuItem sx={buttonStyleMenu}>
                    Environment, Health & <br></br> Safety Services
                  </MenuItem>
                </a>
                <a href={"https://cmp.idsil.com"} style={{ all: "unset" }}>
                  <MenuItem sx={buttonStyleMenu}>
                    Content Management & <br></br> Publishing Services
                  </MenuItem>
                </a>
              </Stack>
            </Menu> */}
            <Stack
              sx={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px",
                gap: "8px",
                display: { xs: "none", md: "flex" },
              }}
            >
              {/* <Button size="medium" sx={buttonStyle}>
              Login
            </Button> */}
              <Button
                target="_blank"
                size="medium"
                href="https://careers.idsil.com/"
                sx={buttonStyle}
              >
                Careers
              </Button>
              <Button
                size="medium"
                href="https://idsil.com/contact-us/"
                sx={[
                  {
                    color: "white",
                    bgcolor: "primary.blue600",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.blue700",
                    },
                  },
                ]}
              >
                Get in touch
              </Button>
            </Stack>
          </Stack>

          <Stack sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </Div>
      <SwipeableDrawer
        PaperProps={{
          sx: { backgroundColor: "primary.main", width: "100%" },
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        anchor="right"
      >
        <Stack mr={3} textAlign={"end"}>
          <div>
            <IconButton onClick={() => setOpen(!open)}>
              <CloseIcon />
            </IconButton>
          </div>
        </Stack>
        <Divider />
        <List
          sx={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ListItem
            onClick={() => {
              navigate("/");
              setOpen(!open);
            }}
            className={
              pathname === "/" && window.location.hash !== "#services"
                ? classes.mobileActive
                : undefined
            }
            sx={buttonStyle}
          >
            Home
          </ListItem>
          <ListItem
            onClick={() => {
              navigate("/about-us");
              setOpen(!open);
            }}
            className={pathname === "/about-us" && classes.mobileActive}
            sx={buttonStyle}
          >
            About Us
          </ListItem>
          <NavHashLink
            onClick={() => {
              setOpen(!open);
            }}
            style={{ width: "100%", textDecoration: "none" }}
            to={"/#services"}
          >
            <ListItem
              className={
                window.location.hash === "#services"
                  ? // pathname.includes("#services")
                    classes.mobileActive
                  : undefined
              }
              sx={buttonStyle}
            >
              Services
            </ListItem>
          </NavHashLink>

          {/* <ListItem
            // id="productsMobile"
            // aria-controls="productsMobile"
            // aria-haspopup="true"
            // aria-expanded={openMenu ? "true" : undefined}
            onClick={handleDropdown}
            sx={buttonStyle}
          >
            Services <ExpandMoreIcon />
          </ListItem> */}
          {/* aria-controls must be same to Menu id */}
          {/* <Menu
              id="productsMobile"
              // open={openMenu}
              anchorEl={anchorEl.anchorEl3}
              onClose={handleClose}
              MenuListProps={{ style: { padding: 0 } }}
            >
              <MenuItem
                onClick={handleClose}
                sx={{
                  bgcolor: "primary.main",
                  color: "primary.gray600",
                  fontSize: "16px",
                }}
              >
                Abba
              </MenuItem>
            </Menu> */}
          {/* {dropdown ? (
            <>
              <ListItem
                onClick={() => setOpen(!open)}
                sx={[buttonStyle, { ml: 4 }]}
              >
                <a
                  href="https://ideasengg.com/en_US/"
                  className="mobile-button"
                >
                  Engineering Services
                </a>
              </ListItem>
              <ListItem
                onClick={() => setOpen(!open)}
                sx={[buttonStyle, { ml: 4 }]}
              >
                <a
                  href="https://ids-technologies.in/"
                  className="mobile-button"
                >
                  Software Services
                </a>
              </ListItem>
              <ListItem
                onClick={() => setOpen(!open)}
                sx={[buttonStyle, { ml: 4 }]}
              >
                <a
                  href="http://healthcare.idsil.com/"
                  className="mobile-button"
                >
                  Healthcare Services
                </a>
              </ListItem>
              <ListItem
                onClick={() => setOpen(!open)}
                sx={[buttonStyle, { ml: 4 }]}
              >
                <a href="https://ehs.idsil.com/" className="mobile-button">
                  Environment, Health & <br></br> Safety Services
                </a>
              </ListItem>
              <ListItem
                onClick={() => setOpen(!open)}
                sx={[buttonStyle, { ml: 4 }]}
              >
                <a href="https://cmp.idsil.com" className="mobile-button">
                  Content Management & Publishing Services
                </a>
              </ListItem>
            </>
          ) : null} */}
          <ListItem
            onClick={() => {
              navigate("/blogs");
              setOpen(!open);
            }}
            className={pathname === "/blogs" && classes.mobileActive}
            sx={buttonStyle}
          >
            Blogs
          </ListItem>
          <ListItem
            onClick={() => {
              navigate("/case-study");
              setOpen(!open);
            }}
            className={pathname === "/case-study" && classes.mobileActive}
            sx={buttonStyle}
          >
            Case Studies
          </ListItem>
          <ListItem onClick={() => setOpen(!open)} sx={buttonStyle}>
            <a
              target="_blank"
              href="https://idsil.com/contact-us/"
              className="mobile-button  w-100"
              rel="noreferrer nopener"
            >
              Careers
            </a>
          </ListItem>
          <ListItem onClick={() => setOpen(!open)} sx={buttonStyle}>
            <a
              target="_blank"
              href="https://idsil.com/contact-us/"
              style={{ width: "100% !important" }}
              className="mobile-button w-100"
              rel="noreferrer nopener"
            >
              Get in touch
            </a>
          </ListItem>
        </List>
      </SwipeableDrawer>
    </AppBar>
  );
}
