import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import GA4React from "ga-4-react";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

if (rootElement.hasChildNodes()) {
  root.hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

try {
  setTimeout(() => {
    const ga4react = new GA4React("G-NETB20TCK0");
    ga4react.initialize().catch((err) => console.error(err));
  }, 4000);
} catch (err) {
  console.error(err);
}
