import { Avatar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Div from "./Layouts/Div";

import avatar1 from "../assets/images/Avatar 1.svg";
import avatar2 from "../assets/images/Avatar 2.svg";

export default function Testimonials() {
  return (
    <Div bgColor={"primary.gray100"}>
      <Stack
        my={"6rem"}
        spacing={2}
        maxWidth={"xl"}
        mx={"auto"}
        // display="flex"
        // direction={{ xs: "column", md: "row" }}
      >
        <Stack>
          <Typography
            sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
            color="primary.blue600"
          >
            Our Testimonials
          </Typography>
          <Typography
            sx={{
              typography: { xs: "displayMdSemi", md: "displayLgSemi" },
              my: "4px",
            }}
            color="primary.gray900"
          >
            Our clients speak about our quality
          </Typography>
          <Stack
            display={"flex"}
            justifyContent={"space-between"}
            direction={{ xs: "column", md: "row" }}
          >
            <Stack
              direction={{ xs: "column", md: "row" }}
              display="flex"
              py={3}
              px={3}
              spacing={5}
            >
              <Stack maxWidth={"500px"} spacing={2} py={2}>
                <Stack spacing={2}>
                  <Avatar alt="Remy Sharp" src={avatar1} />

                  <Typography
                    sx={{ typography: "textMdReg", color: "primary.gray600" }}
                  >
                    IDS has significantly cut our production time by 25% and
                    have shown to be a creative, proactive partner who has
                    assisted us in quality improvement of journals, yet still
                    lowering costs.
                  </Typography>
                </Stack>
                <Stack display={"flex"} direction={"row"} spacing={2}>
                  <Typography sx={{ typography: "textLgSemi" }}>
                    – Director Editorial, one of the top 5 UK publisher
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              display="flex"
              py={3}
              px={3}
              spacing={5}
            >
              <Stack maxWidth={"500px"} spacing={2} py={2}>
                <Stack spacing={2}>
                  <Avatar alt="Remy Sharp" src={avatar2} />

                  <Typography
                    sx={{ typography: "textMdReg", color: "primary.gray600" }}
                  >
                    IDS has been flexible, knowledgeable, and capable of
                    handling large, scalable projects. I have enjoyed working
                    with you and your talented team.
                  </Typography>
                </Stack>
                <Stack display={"flex"} direction={"row"} spacing={2}>
                  <Typography sx={{ typography: "textLgSemi" }}>
                    – Content Integration Manager, one of the top 3 US content
                    aggregator
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Div>
  );
}
