import React from "react";
import { Helmet } from "react-helmet";

const withHelmet = (WrappedComponent, helmetConfig) => {
  return (props) => (
    <>
      <Helmet>
        {helmetConfig.htmlLang && <html lang={helmetConfig.htmlLang} />}
        {helmetConfig.title && <title>{helmetConfig.title}</title>}
        {helmetConfig.metaTags &&
          helmetConfig.metaTags.map((metaTag, index) => (
            <meta key={index} {...metaTag} />
          ))}
        {helmetConfig.metaDescription && (
          <meta name="description" content={helmetConfig.metaDescription} />
        )}
        {helmetConfig.metaThemeColor && (
          <meta name="theme-color" content={helmetConfig.metaThemeColor} />
        )}
      </Helmet>
      <WrappedComponent {...props} />
    </>
  );
};

export default withHelmet;
