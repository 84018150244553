import { Stack } from "@mui/system";
import React from "react";
import BlogSection from "../../Sections/BlogSection";
import ContactUsAbout from "../card/ContactUsAbout";
import GetStarted from "../GetStarted";
import GoToTop from "../GoToTop";
import withHelmet from "../hoc/withHelmet";

const BlogLanding = ({ dark, setDark }) => {
  return (
    <>
      <Stack
        display={"flex"}
        direction={"column"}
        justifyContent="center"
        navbarref="true"
      >
        <GetStarted />
        <BlogSection />
        {/* <JoinUs /> */}
        <ContactUsAbout />
        {/* <LatestSection /> */}
        <GoToTop />
      </Stack>
    </>
  );
};

const helmetConfig = {
  htmlLang: "en",
  title: "Blogs",
  metaDescription: "First Page",
  metaThemeColor: "#E6E6FA",
  metaTags: [{ name: "author", content: "Your Name" }],
};

export default withHelmet(BlogLanding, helmetConfig);
