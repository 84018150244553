import React from "react";
import Button from "@mui/material/Button";
import { Typography, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import "../../assets/images/style.css";
const subHeading = {
  fontSize: { xs: "14px", md: "16px" },
  color: "primary.blue700",
};

const BlogSectionCard = (props) => {
  return (
    <Card
      sx={{
        // maxWidth: 350,
        position: "relative",
        "&:hover": {
          boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
          cursor: "pointer",
        },
        height: { xs: "300px", md: "330px", lg: "350px" },
      }}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={props.thumbnail}
      />
      <CardContent>
        <Stack justifyContent={"space-between"}>
          <Stack Item>
            {" "}
            <Typography
              sx={{ typography: { xs: "textXlSemi", md: "displayXsSemi" } }}
              className="heading_class"
            >
              {props.name}
            </Typography>
          </Stack>
          <Stack Item>
            {" "}
            <Typography
              sx={{ typography: { md: "textMdReg" }, my: "1rem" }}
              color="text.secondary"
              className="content_class"
            >
              {props.content}
            </Typography>
          </Stack>
          <Stack Item>
            <Stack
              my={2}
              direction="row"
              sx={{ position: "absolute", bottom: "10px", left: "10px" }}
            >
              <Button size="small" sx={subHeading}>
                Read post {"  "}
                <ArrowOutwardIcon
                  color="primary.blue700"
                  fontSize="8px"
                  sx={{ mx: "5px" }}
                />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BlogSectionCard;
