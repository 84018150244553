import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Div from "./Layouts/Div";
import Subscribe from "./Form/Subscribe";

// const subHeading = {
//   fontSize: { xs: "14px", md: "16px" },
//   color: "primary.blue600",
// };
// const mainHeading = { fontSize: "48px", color: "#f6f6f6" };
export default function GetStarted() {
  return (
    <Div bgColor={"primary.gray100"}>
      <Stack
        sx={{ backgroundColor: "#f6f6f6" }}
        mx={"auto"}
        maxWidth={"xl"}
        my={8}
      >
        <Stack py={"5%"} sx={{ backgroundColor: "#f6f6f6" }}>
          <Typography
            sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
            // variant="textMdSemi"

            color="primary.blue600"
          >
            Knowledge Center
          </Typography>
          <Typography
            sx={{ typography: { xs: "displayMdSemi", md: "displayLgSemi" } }}
            // variant="displayLgSemi"
          >
            Browse Our Latest Blogs
          </Typography>
          <Typography
            sx={{ typography: { xs: "textLgReg", md: "textXlReg" } }}
            // variant="textXlReg"
          >
            Tools, tips, and strategies modern teams need to help their
            companies grow.
          </Typography>
        </Stack>
        <Stack mb={7}>
          <Stack spacing={3} direction={{ xs: "column", md: "row" }}>
            <Subscribe type="BLOG" />
          </Stack>
          <Stack my={".5rem"}>
            <Typography
              sx={{ fontSize: "14px", color: "primary.gray600", my: "6" }}
            >
              Subscribe to our blog
            </Typography>
          </Stack>
        </Stack>

        {/* <Stack spacing={1}>
            <TextField
              size="small"
              placeholder="Enter your email"
              InputProps={{
                sx: {
                  padding: "4px",
                  "&.Mui-focused": {
                    backgroundColor: "primary.white",
                    border: "1px solid ",
                    borderColor: "primary.blue600",
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  },
                },
              }}
            ></TextField>
            <Typography sx={{ fontSize: "14px", color: "primary.gray600" }}>
              Subscribe to our blog
            </Typography>
          </Stack>
          <Stack>
            <Button
              size="medium"
              sx={[
                {
                  color: "white",
                  bgcolor: "primary.blue600",
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "12px 20px",
                  lineHeight: "24px",
                },
                {
                  "&:hover": {
                    backgroundColor: "primary.blue700",
                  },
                },
              ]}
            >
              Subscribe
            </Button>
          </Stack> */}
      </Stack>
    </Div>
  );
}
