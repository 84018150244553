import machineLearning from "../assets/images/blogs/images/machine-learning.webp";
import researchAuthorship from "../assets/images/blogs/images/research-authorship.webp";
import ContentAggregators from "../assets/images/blogs/images/content-aggregators.webp";
import AI from "../assets/images/blogs/images/AI.webp";
import machineLearningThumbnail from "../assets/images/blogs/images/machine-learning.webp";
import researchAuthorshipThumbnail from "../assets/images/blogs/thumbnails/research-authorship.webp";
import ContentAggregatorsThumbnail from "../assets/images/blogs/thumbnails/content-aggregators.webp";
import AIThumbnail from "../assets/images/blogs/thumbnails/AI.webp";

const blogs = [
  {
    id: 1,
    name: "Data Annotation for Machine Learning",
    thumbnail: machineLearningThumbnail,
    image: machineLearning,
    intro: `Machine learning and Artificial intelligence are the buzzwords these days.
      Organizations worldwide are looking for ways to introduce automation to
      their workflow to bring in unseen process efficiency and cost
      optimization.`,
    content: `<div class="blogBox">
    <p>
      Machine learning and Artificial intelligence are the buzzwords these days.
      Organizations worldwide are looking for ways to introduce automation to
      their workflow to bring in unseen process efficiency and cost
      optimization. Driving the surge in AI or machine learning is the access to
      plentiful data made available from the internet, social media, sensors,
      and other sources. Even autonomous driving is being realized today due to
      impeccable AI models trained with a range of datasets aggregated from
      unstructured and structured sources. Data can be almost any kind of data
      that a human mind can understand. Examples of such data include images,
      texts, audio/video materials, 3D models from MRIs, Tabular and Time-series
      data, LIDAR and RADAR data, and any other kind of data one can imagine.
      These datasets are pivotal to any ML/AI project to yield authentic
      results. But to meet this end, the raw datasets need to be annotated or
      labeled correctly.
    </p>
    <p>
      Under data labeling, raw datasets are labeled, marked, colored, or
      highlighted to mark up features of differences, similarities, or types.
      Data labeling can be generic or domain-specific, depending on the use
      cases. It helps a machine learning algorithm rightfully identify the data
      and learn from it. Learning algorithms are more advanced these days, and
      they can absorb millions of more datasets and, hence, be more accurate.
      Feeding another million datasets to an algorithm will only improve its
      accuracy if the data quality is fine. Advanced learning algorithms have
      fueled an unending hunger for well-annotated and labeled data for AI
      applications.
    </p>
    <p>
      According to a report from the market research firm Cognilytica, data
      engineering tasks including identification, aggregation, cleansing,
      labeling, and augmentation account for more than 80% of the time involved
      in most AI and machine-learning projects. ML algorithm development, model
      training, tuning, and operationalization consume only 20% of the project
      time. Out of 80% data engineering time, data cleansing and data labeling
      tasks take a whopping 50%. This time consumption statistics highlights the
      significance of the data annotation task.
    </p>
    <p>
      Types of Annotation: There are different types of data annotation
      services, including:
    </p>
    <ul style="margin-left: 20px">
      <li><strong>Structured Data Annotation,</strong></li>
      <li><strong>Text Annotation,</strong></li>
      <li><strong>Image/Video Annotation, and</strong></li>
      <li><strong>Audio Annotation.</strong></li>
    </ul>
    <p>
      Annotation techniques may vary per the concerned use cases. Primary
      image/video annotation techniques include:
    </p>
    <ol style="margin-left: 20px">
      <li>
        <strong> 2D Bounding Box Annotation: </strong>Bounding boxes are
        imaginary boxes drawn around objects in an image. These boxes are
        labeled to help ML algorithms recognize those objects.
      </li>
      <li>
        <strong> Semantic Segmentation</strong>: It involves highlighting parts
        of an image together which belong to the same object.
      </li>
      <li>
        <strong> 3D Cuboid Annotation: </strong>3D Cuboid Annotation is meant
        for 3D perception from 2D images and videos.
      </li>
      <li>
        <strong> Polygon Annotation: </strong>Polygon Annotation detects the
        exact shape of an object by drawing pixel-perfect polygons.
      </li>
      <li>
        <strong> Polyline Annotation: </strong>Polyline annotation is crucial
        for autonomous vehicle perception models, which helps autonomous
        vehicles detect lanes.
      </li>
      <li>
        <strong> Landmark Annotation: </strong>Landmark annotations mark the
        gestures and humans’ movement trajectory with each point in motion,
        helping machines recognize human faces or poses.
      </li>
    </ol>
    <p>
      IDS has deep expertise in annotation techniques and offers services for
      facilitating ground truth datasets for machine learning projects,
      including<strong>
        Structured Data Annotation, Text Annotation,&nbsp; Audio Annotation, and
        Image/Video Annotation.</strong
      >
    </p>
  </div>
</div>
`,
    half_content: `Machine learning and Artificial intelligence are the buzzwords these days. Organizations worldwide are looking for ways to introduce automation.......`,
    added_by: "Admin",
    date: "2nd, August, 2021",
  },
  {
    id: 2,
    name: "Research Authorship",
    thumbnail: researchAuthorshipThumbnail,
    image: researchAuthorship,
    intro: `Information is a refined compilation of data about a particular object of
      interest. Knowledge is the assimilation of such information to gain an
      understanding of the object.`,
    content: `<div class="blogTxt p-0">
    <p>
      Information is a refined compilation of data about a particular object of
      interest. Knowledge is the assimilation of such information to gain an
      understanding of the object. Information is not knowledge in the real sense
      of the term but, at the same time, a must for knowledge attainment. In these
      days of the World Wide Web, information is just a click away.&nbsp; You name
      a particular topic and hit the search on the Web. You’ll come across
      millions of Web pages with information of your interest. However, a word of
      caution here is Authenticity. Information authenticity is crucial as
      misinformation is deadly for the sake of knowledge.
    </p>
    <p>
      Moreover, one may wander across Web pages and get confused with
      topic-specific jargon, eventually losing interest in the topic. It
      frequently happens with information seekers and is an issue that needs the
      attention of information and knowledge service providers. The lucidity issue
      is more important for students and other novice seekers who want to become
      familiar with a particular topic. It is challenging for them to find the
      correct information to arouse their interest and spark their curiosity and
      imagination. They need authentic information in the simplest form of
      expression. They need to know the meaning of specific jargon and concepts
      which are associated with a topic. Every knowledge seeker rejoices while
      going through a write-up that quenches the academic thirst and provides
      direction for further sophistication.&nbsp; This is where audience-specific
      authorship comes to play its role.
    </p>
    <p>
      Research Authorship is an exclusive service offering catering to the quest
      of knowledge enthusiasts, be it students or researchers. Comprehensive
      research is done to explore the topic at hand while referring to reputed and
      authentic information sources only.&nbsp; These articles offer general but
      key information about the topic as an overview of the topic.&nbsp; These
      write-ups provide comprehensive background and perspective so that
      researchers can have a better understanding of the topic. This service
      ensures lucidity in information flow and information completeness till date
      with all updates. It ensures end-users are well prepared for knowledge
      enrichment with a more specific search if it is needed.
    </p>
    <p>
      IDS has added another feather to its cap for serving the researchers
      fraternity with its Research Authorship offering.&nbsp; We create scholarly
      yet simple and easy-to-understand essays on topics across various subject
      domains, including Applied Sciences, Biotechnology, Medical Sciences,
      Health, Humanities, Business, Art, Music, History, Law, International
      Relations and Politics, Social Issues, and many more. These essays are
      verified on Lexile-scale for their readability and difficulty level. We
      ensure to achieve the ease of readability Lexile score meant for school
      students for our write-ups.&nbsp; Moreover, we provide bibliographic
      citations to each of our essays to help the audience dive deeper if it is
      required.
    </p>
  </div>
  `,
    half_content: `Information is a refined compilation of data about a particular object of interest. Knowledge is the assimilation of such information to gain an understanding.....`,
    added_by: "Admin",
    date: "2nd, August, 2021",
  },
  {
    id: 3,
    name: "Content Aggregators and IDS A&I: A Symbiosis Benefiting Researchers Worldwide",
    thumbnail: ContentAggregatorsThumbnail,
    image: ContentAggregators,
    intro: ` In the days of access to the unlimited World Wide Web, why someone would
      even think of subscribing to a database content aggregator.`,
    content: `<div class="blogTxt p-0">
    <p>
      In the days of access to the unlimited World Wide Web, why someone would
      even think of subscribing to a database content aggregator. Well, one may
      explore thousands of Web pages for a single query, s/he can never rely on
      the information retrieved. Verifying “authenticity of information” remains
      an elusive exercise and often results in confusion and chaos. There comes
      the role of “content aggregators” which maintain dedicated databases to
      cater to the information quest of diverse seekers, be it intelligentsia,
      students, professionals or businessmen.
    </p>
  
    <p>
      Database content aggregators take utmost care while sourcing articles from
      external resources and they focus on information credibility. It eventually
      means that when a person explores a topic at these databases, information
      authenticity is assured which is more accurate, current, and reliable than
      what one finds via a Web search. Ultimately, this saves precious time and
      helps produce higher-quality work.
    </p>
  
    <p>
      These aggregators offer databases dedicated to specific subject matters that
      help the concerned ones to enhance their knowledge and improve the outcome.
      For example: nursing databases may help nurses with evidence-based resources
      to streamline their workflow and improve patient care, likewise a database
      on the American Revolution may help researchers explore seminal events
      leading up to the founding of the new nation and of the people involved in
      these struggles. Subject specific databases empower researchers to
      experience and respond to information differently than they generally would
      do for information through a textbook.
    </p>
  
    <p>
      Assisting researchers find the article of their interest in a fraction of
      time is one of basic challenge content aggregators usually face. Here comes
      the role of keyword-rich
      <strong>Abstracting and Indexing (A&amp;I)</strong> which enables
      information seekers to locate their articles in no time irrespective of the
      vastness of information repositories. At
      <strong>IDS Infotech Ltd. </strong>,our highly-skilled workforce uses the
      abstracting and indexing methodology in identifying the best keywords, that
      act as metadata for a given scholarly or general interest article, and
      represent them in their abstracts and indexing terms. The abstracting and
      indexing output generated by our professionals are very accurate and assists
      researchers in finding the articles they are interested in while browsing
      through databases of content aggregators.
    </p>
  </div>
  `,
    half_content: `In the days of access to the unlimited World Wide Web, why someone would even think of subscribing to a database.....`,
    added_by: "Admin",
    date: "2nd, August, 2021",
  },
  {
    id: 4,
    name: "Writeup For FL AI and VA",
    thumbnail: AIThumbnail,
    image: AI,
    intro: `English publications have historically been at forefront to touch almost
      every sphere of human life by providing not only amusement to mankind in
      general but also by spreading findings of scholarly significance to the
      global audience.`,
    content: `<div class="blogTxt p-0">
    <p>
      English publications have historically been at forefront to touch almost
      every sphere of human life by providing not only amusement to mankind in
      general but also by spreading findings of scholarly significance to the
      global audience. However, English is not only language in this practice of
      information dissemination, non-English publications have supported their
      target audience in their quest for amusement and research at par with the
      English publications. Non-English literature, especially German, French and
      Spanish, have played an equally important role in almost every discipline
      including Art, Law and History. They are treasure troves in their own and
      have great significance for English audience too. With the wealth of
      information they contain and their extraordinary research value, they are of
      no less importance than English publications. Value added Abstracting and
      Indexing (A&amp;I) of non-English content in English language provides an
      opportunity to readers and scholars worldwide to get a glimpse of this
      invaluable literature and take their research to their next level of
      sophistication.
    </p>
    <p><b>How Is It Done?</b></p>
    <p>
      In this digital age of civilization, there is a great demand for information
      stored in English and German, French and Spanish publications, especially in
      the field of Art, Law and History. Content aggregators work towards making
      such information from different sources available at one place with all
      their authenticity kept intact and facilitating easy search and information
      retrieval to their subscribers. They take help of Abstracting and Indexing
      of the original content by a skilled A&amp;I workforce who creates abstracts
      enriched with the best representative keywords of the original content.
    </p>
    <p><b>Further Value Addition to A&amp;I</b></p>
    <p>
      German, French and Spanish publications dealing with subjects like Art, Law
      and History contain plethora of information such as artworks of historical
      significance, style of the arts, artists, important dates, geographical
      details, landmark court cases and clauses among others, which are extracted
      and tagged to the source A&amp;I and stored in digital databases to help
      end-users locate the article of their interest
    </p>
  </div>
  `,
    half_content: `English publications have historically been at forefront to touch almost every sphere of human life by providing not only amusement to mankind......`,
    added_by: "Admin",
    date: "11th, February, 2021",
  },
];

export default blogs;
