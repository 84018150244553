import avtar1 from "../assets/images/Avatar 1.svg";
import avtar2 from "../assets/images/Avatar 2.svg";
const TestimonialCardData = [
  {
    Heading:
      "IDS has significantly cut our production time by 25% and have shown to be a creative, proactive partner who has assisted us in quality improvement of journals, yet still lowering costs.",
    Avtar: avtar1,
    Name: "Director Editorial",
    Designation: "one of the top 5 UK publisher",
  },
  {
    Heading:
      "IDS has been flexible, knowledgeable, and capable of handling large, scalable projects. I have enjoyed working with you and your talented team.",
    Avtar: avtar2,
    Name: "Content Integration Manager",
    Designation: "one of the top 3 US   content aggregator",
  },
  // {
  //     SubHeading:"Financial Services",
  //     Heading:"Untitled has saved us thousands of hours of work. We’re able to spin up projects and features faster.",
  //     Avtar:avtar,
  //     Name:"NameXYZ",
  //     Designation:"ABCD"
  // },
];
export default TestimonialCardData;
