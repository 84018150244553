import React from "react";

export default class useApi extends React.Component {
  static baseUrl = process.env.REACT_APP_API_URL;
  static apiKey = process.env.REACT_APP_API_KEY;
  static statusCode = 500;

  // ? ********************************** Post Request ******************************** */
  static async postRequest( url, isToken = true, formData = null, apiKeyName = null, headers = null, params = null) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "POST",
        body: JSON.stringify(formData),
        headers: this.config(isToken, apiKeyName, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something Went wrong! Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? ******************************** Get Request ******************************** */
  static async getRequest(url, isToken = true, apiKeyName = null, headers = null, params = null) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "GET",
        headers: this.config(isToken, apiKeyName, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something Went wrong! Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? ******************************** Delete Request ******************************** */
  static async deleteRequest(url, isToken = true, apiKeyName = null, headers = null, params = null
  ) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "DELETE",
        headers: this.config(isToken, apiKeyName, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something Went wrong! Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? *********************** Update request with patch method *************************** */
  static async updateRequest(url, isToken = true, formData = null, apiKeyName = null, headers = null, params = null
  ) {
    try {
      const response = await fetch(`${this.baseUrl}${url}`, {
        method: "PATCH",
        body: JSON.stringify(formData),
        headers: this.config(isToken, apiKeyName, headers, params),
      });
      if (!response.ok) {
        throw new Error("Something went wrong. Try again!");
      }
      return await response.json();
    } catch (error) {
      return error;
    }
  }

  // ? ***************** Configurations of header and parameters ************************ */
  static config(isToken = true, apiKeyName = null, headers = null, parameters = null) {
    let defaultHeaders = {
      "content-type": "application/json",
      "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
      "Content-Security-Policy": "directive 'source';",
      "X-Frame-Options": "SAMEORIGIN",
      "x-api-key": apiKeyName === null ? this.apiKey : process.env[apiKeyName],
    };
    if (isToken) {
      const token = localStorage.getItem("token");
      if (token === null) {
        throw new Error("Access Denied. Try again!");
      }
      defaultHeaders = {
        ...defaultHeaders,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }

    if (headers !== null) {
      defaultHeaders = { ...defaultHeaders, ...headers };
    }

    return defaultHeaders;
  }

  // ? ***************** Throw the new errors According to status codes ************************ */
  static throwNewErrors(message, statusCode) {
    // throw Error(JSON.stringify({ code: statusCode, message: message }));
    throw Error(message);
  }
}
