import logomark1 from "../assets/images/logomark1.webp";
import logomark_2 from "../assets/images/logomark2.webp";
import logomark_3 from "../assets/images/logomark3.webp";
import logomark_4 from "../assets/images/logomark4.webp";
import logomark_6 from "../assets/images/logomark6.webp";
import logomark_7 from "../assets/images/logomark1.webp";

const CaseStudyCardData = [
  {
    type: `content-solutions`,
    Heading: "Content Solutions",
    SvgIcons: logomark1,
    SubHeading: "Electronic Data Capture",
    Paragraph:
      "Assisted a leading US-based client by indexing 1 million+ articles per year and creating both article metadata files and 700K article abstracts.",
    BgCardColor: "#0086C9",
    anchor: "https://cmp.idsil.com/case_study/content-solutions/",
  },
  {
    type: `historic-periodicals`,
    Heading: "Abstracting and Indexing ",
    SvgIcons: logomark_6,
    SubHeading: "Historic Periodicals",
    Paragraph:
      "Assisted the client with the processing of thematic collections of historical publications. Our LEAN methodology and content-enabled quick database supplementation.",
    BgCardColor: "#0086C9",
  },

  {
    type: `RPA-solutions`,
    Heading: "RPA Solution",
    SvgIcons: logomark_3,
    SubHeading: "RPA Solutions For Business Efficiency ",
    Paragraph:
      "Automated the email monitoring and downloading process for efficient daily delivery of extracted and converted magazine and journal content",
    BgCardColor: "#1570EF",
    anchor: "https://cmp.idsil.com/case_study/content-solutions/",
  },
  {
    type: `prepress-Service`,
    Heading: "Prepress Solutions",
    SvgIcons: logomark_4,
    SubHeading: "E-publishing",
    Paragraph:
      "Provided expert typesetting and e-publishing services for book titles and e-book conversion, and helped the client win the repeat Global RFP.",
    BgCardColor: "#444CE7",
    anchor: "https://cmp.idsil.com/case_study/content-solutions/",
  },
  {
    type: `legal-content`,
    Heading: "Legal Content",
    SvgIcons: logomark_2,
    SubHeading: "Electronic Data Capture",
    Paragraph:
      "Provided legal info files from 100K+ articles using the LEAN method & high-quality content, enabling the client to swiftly supplement their databases and gain a competitive edge.",
    BgCardColor: "#039855",
    anchor: "https://cmp.idsil.com/case_study/content-solutions/",
  },
 
  {
    type: `Native-American-History`,
    Heading: "Abstracting and Indexing",
    SvgIcons: logomark_7,
    SubHeading: "Native American History",
    Paragraph:
      "Helped the client to launch its new product offerings ahead the schedule with our LEAN production methodology and high-quality indexes.",
    BgCardColor: "#E04F16",
  },
  {
    type: `Product-Curation`,
    Heading: "E-commerce Solution",
    SvgIcons: logomark_7,
    SubHeading: "Product Curation ",
    Paragraph:
      "Helped the client with high-volume data curation for consumers online through data-driven commerce, media, and knowledge management software solutions.",
    BgCardColor: "#525252",
  },
];

export default CaseStudyCardData;
