import React from "react";
import { Typography, Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ServiceCard from "../card/ServiceCard";
import ServiceCardData from "../../store/ServiceCardData";
import "../../assets/images/style.css";
import Div from "../Layouts/Div";

const ServiceSection = () => {
  return (
    <Div bgColor="#001E3C">
      <Stack id="services">
        <Stack
          my={{ xs: 1, sm: 1, md: 10 }}
          sx={{ backgroundColor: "#001E3C", color: "#ffffff" }}
        >
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 3, md: 5 }}
            columnSpacing={{ xs: 0, sm: 3, md: 5 }}
            py={10}
            justifyContent={"center"}
            mx={"auto"}
            maxWidth={"xl"}
            width="100%"
          >
            <Grid Item lg={5} md={5}>
              <Typography
                className="case"
                sx={{ position: "sticky", top: "8rem" }}
              >
                <Typography
                  sx={{
                    typography: {
                      xs: "textLgSemi",
                      md: "textMdSemi",
                      xl: "displayXsSemi",
                    },
                  }}
                >
                  Our Core Services
                </Typography>
                <Typography
                  sx={{
                    typography: {
                      xs: "displaySmSemi",
                      md: "displayMdSemi",
                      Lg: "displayMdSemi",
                    },
                  }}
                  my={2}
                >
                  Delivering seamless solutions to address modern-day content
                  needs
                </Typography>
                <Typography
                  sx={{
                    typography: "textLgReg",
                  }}
                >
                  Providing technology-enabled content and publishing solutions
                  for more than 30+ years to professional publishers.
                </Typography>
              </Typography>
            </Grid>
            <Grid container lg={7}>
              {ServiceCardData.map((ServiceValue, Index) => (
                <Grid
                  Item
                  md={6}
                  sm={6}
                  sx={{ spacing: { xl: "4rem" }, borderRadius: "13px" }}
                >
                  <ServiceCard
                    Title={ServiceValue.Title}
                    Paragraph={ServiceValue.Paragraph}
                    Action1={ServiceValue.Action1}
                    Action2={ServiceValue.Action2}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Div>
  );
};

export default ServiceSection;
