import React from "react";
import { Grid, Typography, Button, Stack } from "@mui/material";
import BlogSectionCard from "../card/BlogSectionCard";
import BlogsCardData from "../../store/BlogsData";
import { Link, useParams } from "react-router-dom";
import Div from "../Layouts/Div";

const BlogSection = () => {
  const params = useParams();

  return (
    <Div bgColor={"primary.gray100"}>
      <Stack
        sx={{
          px: { sm: "3rem", md: "3rem" },
          my: "4rem",
        }}
        mx={"auto"}
        maxWidth={"xl"}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
        >
          <Stack Item>
            <Typography
              sx={{
                typography: { xs: "textLgSemi", md: "textMdSemi" },
                color: "primary.blue700",
              }}
            >
              Knowledge Center
            </Typography>
            <Typography
              sx={{
                typography: { xs: "displaySmSemi", md: "displayMdSemi" },
                my: "4px",
              }}
            >
              Browse Our Latest Blogs
            </Typography>
            <Typography
              sx={{
                typography: { xs: "textLgReg", md: "textXlReg" },
                my: "10px",
              }}
            >
              Tools, tips, and strategies modern teams need to help their
              companies grow. 
            </Typography>
          </Stack>
          <Stack Item>
            <Link to="/blogs" style={{ textDecoration: "none" }}>
              <Button
                size="medium"
                sx={[
                  {
                    width: "100%",
                    color: "white",
                    bgcolor: "primary.blue600",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "12px 20px",
                    lineHeight: "24px",
                  },
                  {
                    "&:hover": {
                      bgcolor: "primary.blue700",
                    },
                  },
                ]}
              >
                View all Blogs
              </Button>
            </Link>
          </Stack>
        </Stack>
        <Stack display={"flex"} justifyContent="center" alignItems={"center"}>
          <Stack maxWidth={"xl"}>
            <Grid
              container
              display={"flex"}
              // justifyContent="center"
              alignItems={"center"}
              spacing={5}
              my={2}
            >
              {BlogsCardData.filter((blog) =>
                params.id !== undefined ? blog.id !== parseInt(params.id) : blog
              ).map(
                (blog, index) =>
                  index <= 2 && (
                    <Grid item xs={12} md={4} key={blog.id}>
                      <Link
                        to={`/blogs/${blog.id}`}
                        key={blog.id}
                        style={{ textDecoration: "none" }}
                      >
                        <BlogSectionCard
                          name={blog.name}
                          thumbnail={blog.thumbnail}
                          content={blog.half_content}
                        />
                      </Link>
                    </Grid>
                  )
              )}
            </Grid>
          </Stack>
        </Stack>
      </Stack>
    </Div>
  );
};

export default BlogSection;
