import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import JoinIdsCard from "../card/JoinIdsCard";
import JoinIdsCardData from "../../store/JoinIdsCardData";
import Div from "../Layouts/Div";
import { Stack } from "@mui/system";

const JoinIds = () => {
  return (
    <>
      <Div>
        <Stack
          sx={{ px: { md: "3rem" }, my: "6rem" }}
          mx={"auto"}
          maxWidth={"xl"}
        >
          <Typography sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                typography: { xs: "textSmSemi", md: "textMdSemi" },
                color: "primary.blue600",
              }}
            >
              Our Differentiators
            </Typography>
            <Typography
              sx={{ typography: { xs: "displayMdSemi", md: "displayMdSemi" } }}
              my={2}
            >
              Why Join Hands With IDS?
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                typography: "textLgReg",
                mx: { xs: "0rem", md: "10rem" },
              }}
            >
              Our comprehensive technology-driven end-to-end services cater to
              different stages of content management and publishing lifecycles.
              Our team ensures each piece of content attains its full potential,
              enabling clients to redefine the value of their content for
              multiplatform consumption.
            </Typography>
          </Typography>
          <Grid
            container
            rowspacing={6}
            mt={4}
            justifyContent={"center"}
            alignContent={"center"}
          >
            {JoinIdsCardData.map((JoinValue, Index) => (
              <Grid Item lg={4} md={6}>
                <JoinIdsCard
                  Svg={JoinValue.Svg}
                  Heading={JoinValue.Heading}
                  Subheading={JoinValue.Subheading}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Div>
    </>
  );
};

export default JoinIds;
